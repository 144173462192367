import React from 'react';
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Image,
  Tag,
  TagLabel,
  Text,
  Link,
  Tooltip,
} from '@chakra-ui/react';
import ConFetti from '../../assets/confetti.svg';
import BellRinging from '../../assets/notification-vactor.svg';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const AlertHistoryItem = ({ alertItem, showOriginalPrice = false, counter }) => {
  // Move useNavigate to the top level
  const navigate = useNavigate();

  // Guard against missing alertItem after hooks
  if (!alertItem) return null;

  const originalPrice = alertItem.originalPrice || alertItem.scrapedPrice;
  const discountPercentage = alertItem.originalPrice
    ? Math.round(
        ((alertItem.originalPrice - alertItem.scrapedPrice) / alertItem.originalPrice) * 100
      )
    : 0;
  let discountPercentageValue = `${discountPercentage}%`;
  if (discountPercentage < 0) {
    discountPercentageValue = `+${Math.abs(discountPercentage)}%`;
  } else if (discountPercentage > 0) {
    discountPercentageValue = `-${discountPercentage}%`;
  }

  return (
    <Grid
      bg="white"
      templateColumns="repeat(10, 1fr)"
      alignItems="center"
      borderRadius="8px"
      mt="8px"
      gap={{ base: '0px', md: '4px' }}
      p={{ base: '4px 8px', md: '8px 12px', xl: '8px 20px' }}
    >
      {/* Date/Time */}
      <GridItem
        colSpan="1"
        color="purchr.gray.light"
        fontSize={{ base: '10px', '2xl': '12px' }}
        fontWeight="400"
        display={{ base: 'none', xl: 'block' }}
        minW="120px"
      >
        {moment(alertItem.scrapedAt).format('Do MMM @ h:mma')}
      </GridItem>

      {/* Image and Product Name */}
      <GridItem colSpan={{ base: '5', md: '6', lg: '4' }}>
        <Flex flex="1" alignItems="center" gap={{ base: '12px', xl: '20px' }}>
          <Box w="40px" h="40px">
            <Image
              src={alertItem.thumbnailURL || 'https://via.placeholder.com/40'}
              minW="40px"
              h="40px"
              borderRadius="4px"
              objectFit="contain"
              fallbackSrc="https://via.placeholder.com/40"
            />
          </Box>
          <Box display="flex" flexWrap="nowrap" flexDirection="column">
            <Link
              href={alertItem.URL}
              color="purchr.blue.dark"
              fontSize="11px"
              textDecoration="underline"
              fontWeight="700"
              maxWidth={{
                base: '142px',
                md: '184px',
                lg: '174px',
                xl: '300px',
                '3xl': '488px',
              }}
              overflow="hidden"
              textOverflow="ellipsis"
              noOfLines="2"
              isExternal
            >
              {alertItem.name || 'Unnamed Item'}
            </Link>
            <Text
              color="purchr.gray.light"
              fontSize="10px"
              fontWeight="400"
              display={{ base: 'block', xl: 'none' }}
              pt="4px"
            >
              {moment(alertItem.scrapedAt).format('Do MMM @ h:mma')}
            </Text>
          </Box>
        </Flex>
      </GridItem>

      {/* Original Price */}
      <GridItem
        minW="54px"
        colSpan="1"
        color="purchr.gray.light"
        fontSize="12px"
        fontWeight="600"
        display={{ base: 'none', lg: 'block' }}
      >
        {showOriginalPrice && (
          <Tooltip label="Original price when added" hasArrow w="200px" placement="right">
            <Box sx={{ display: 'inline-block' }}>
              ${parseFloat(originalPrice).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
            </Box>
          </Tooltip>
        )}
      </GridItem>

      {/* Scraped Price with Discount */}
      <GridItem colSpan={{ base: '5', md: '4', lg: '3', xl: '2' }}>
        <Tag
          p="8px"
          minW="144px"
          bg="purchr.orange.light"
          color="white"
          borderRadius="4px"
          alignItems="flex-start"
          lineHeight="normal"
          gap="4px"
          ms={{ base: '24px', md: '12px', lg: '0px' }}
        >
          <Image src={ConFetti} w="14px" h="14px" />
          <TagLabel display="flex" alignSelf="end" gap="4px" fontSize="13px" lineHeight="normal">
            <Text fontWeight="600">${parseFloat(alertItem.scrapedPrice).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Text>
            <Text fontWeight="400">{`(${discountPercentageValue})`}</Text>
          </TagLabel>
        </Tag>
      </GridItem>

      {/* Edit Item */}
      <GridItem
        minW="70px"
        colSpan="1"
        fontSize="12px"
        fontWeight="400"
        cursor="pointer"
        color="purchr.gray.light"
        display={{ base: 'none', lg: 'block' }}
      >
        <Box
          cursor="pointer"
          onClick={() => {
            localStorage.setItem('search', alertItem.name);
            navigate('/');
          }}
        >
          {`Edit Item >`}
        </Box>
      </GridItem>

      {/* Alert Counter */}
      <GridItem colSpan="1" display={{ base: 'none', lg: 'block' }}>
        <Tooltip label="Price drop found counter" hasArrow w="190px" placement="right">
          <Tag
            minW="48px"
            p="8px"
            gap="6px"
            bg="#E6E6E6"
            borderRadius="4px"
            color="purchr.gray.light"
          >
            <Image src={BellRinging} w="10px" h="10px" />
            <TagLabel fontSize="13px" fontWeight="600">
              {counter || 0}
            </TagLabel>
          </Tag>
        </Tooltip>
      </GridItem>
    </Grid>
  );
};

export default AlertHistoryItem;
