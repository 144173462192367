import React from 'react';
import { Flex, Icon, Image, Text } from '@chakra-ui/react';
import { MdDone } from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import NoItemFound from '../../assets/NoItem_Illustration.svg';

const ItemNotFound = () => {
  return (
    <Flex justifyContent='center' alignItems='center' height='100%' pt='70px'>
      <Flex
        flexDir='column'
        alignItems='center'
        justifyContent='center'
        gap='4px'
        padding={{ base: '100px 40px 28px 40px', lg: '100px 100px 48px 100px' }}
        border='2px dashed #D9D9D9'
        borderRadius='24px'
        pos='relative'
      >
        <Image
          src={NoItemFound}
          pos='absolute'
          top={{ base: '-74%', lg: '-60%' }}
        />
        <Text
          fontSize={{ base: '20px', lg: '26px' }}
          fontWeight='700'
          color='purchr.gray.light'
          mb={{ base: '16px', lg: '28px' }}
        >
          No items to see here ...
        </Text>

        <Flex alignItems='center' gap='4px'>
          <Flex
            p='0.5'
            bg='#27d042'
            border='1px solid greenyellow'
            borderRadius='6px'
            overflow='hidden'
            boxShadow='inset 0 0 10px green'
          >
            <Icon as={MdDone} color='white' fontWeight='bold' fontSize='18px' />
          </Flex>
          <Flex
            fontSize={{ base: '14px', lg: '20px' }}
            alignItems='center'
            gap='4px'
          >
            <Text>Save items using </Text>
            <NavLink
              to='https://chromewebstore.google.com/detail/purchr/blljpkobmadahidjcappomekbkpnnbaf'
              target='_blank'
            >
              <Text color='#0057FF' textDecoration='underline'>
                Purchr Extention
              </Text>
            </NavLink>
          </Flex>
        </Flex>

        <Flex
          fontSize={{ base: '14px', lg: '20px' }}
          alignItems='center'
          gap='4px'
        >
          <Text color='purchr.gray.light'>Still having trouble ?</Text>
          <NavLink
            to='https://app.purchr.com/my-account#feedback'
            target='_blank'
          >
            <Text color='#0057FF' textDecoration='underline'>
              Tell us!
            </Text>
          </NavLink>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ItemNotFound;
