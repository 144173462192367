import React, { useContext, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router';
import { AuthContext } from '../../../context/auth.context';
import {
  Badge,
  Box,
  Flex,
  Icon,
  Image,
  Tab,
  TabList,
  Tabs,
  Text,
} from '@chakra-ui/react';
import LogoImage from '../../../assets/logo.webp';
import Avtar from '../../../assets/avtar.jpg';
import { TbBellRinging } from 'react-icons/tb';
import { NavLink } from 'react-router-dom';
import { BiSolidBinoculars } from 'react-icons/bi';

export default function MobileLayout() {
  const { currentUser } = useContext(AuthContext);

  const location = useLocation();
  const history = useNavigate();

  let defaultTabIndex = 3;
  const [tabIndex, setTabIndex] = useState(defaultTabIndex);
  if (location.pathname.includes('alerts')) {
    defaultTabIndex = 1;
  } else if (location.pathname === '/') {
    defaultTabIndex = 0;
  }

  useEffect(() => {
    if (location.pathname.includes('alerts')) {
      return setTabIndex(1);
    }

    if (location.pathname.includes('my-account')) {
      return setTabIndex(2);
    }

    if (location.pathname === '/') {
      return setTabIndex(0);
    }

    return setTabIndex(3); // set to 3 to unselect all
  }, [location]);

  const handleTabsChange = (index) => {
    setTabIndex(index);
  };

  useEffect(() => {
    if (currentUser && !currentUser.onboardingCompleted) {
      return history('/register');
    }
  }, [location, currentUser, history]);

  // const handleItemClick = async (name) => {
  //   if (name === 'Sign out') {
  //     await logoutUser();
  //   }
  // };

  return (
    <Box bg='#f3f3f3'>
      <Box p='24px 20px 0px 12px' h={`calc(${window.innerHeight}px - 84px)`} overflow='auto'>
        <NavLink to='/' style={{ position: 'sticky', top: 0 }}>
          <Image src={LogoImage} h='60px' ps='4px' />
        </NavLink>
        <Box h={`calc(${window.innerHeight}px - 196px)`} pt='12px'>
          <Outlet />
        </Box>
      </Box>
      <Box
        w='100%'
        h='96px'
        borderRadius='24px 24px 0 0'
        bg='#fff'
        position='fixed'
        bottom={0}
      >
        <Tabs variant='unstyled' index={tabIndex} onChange={handleTabsChange}>
          <Flex gap='24px' p='12px 24px 12px 24px'>
            <TabList alignItems='center' gap='12px'>
              <NavLink to='/'>
                <Tab
                  minWidth='120px'
                  borderRadius='60px'
                  p='16px'
                  bg='#f3f3f3'
                  color='purchr.gray.700'
                  _selected={{ bg: 'purchr.brown.dark', color: '#fff' }}
                  flex='1'
                >
                  <Icon as={BiSolidBinoculars} h='24px' w='24px' />
                </Tab>
              </NavLink>
              <NavLink to='/alerts'>
                <Tab
                  minWidth='120px'
                  borderRadius='72px'
                  p='16px'
                  bg='#f3f3f3'
                  color='purchr.gray.700'
                  _selected={{ bg: 'purchr.brown.dark', color: '#fff' }}
                  position='relative'
                >
                  <Icon as={TbBellRinging} h='24px' w='24px' />
                  <Badge
                    borderRadius='24px'
                    bg='purchr.orange.light'
                    color='white'
                    position='absolute'
                    p='4px 8px'
                    top='-20%'
                    right='4px'
                  >
                    <Text fontFamily='Inter' fontSize='12px' fontWeight='800'>
                      32
                    </Text>
                  </Badge>
                </Tab>
              </NavLink>
            </TabList>
            <NavLink to='/my-account'>
              <Tab
                _selected={{ bg: 'purchr.brown.dark' }}
                borderRadius='160px'
                p='0px'
                border='none'
              >
                <Image src={Avtar} w='64px' h='64px' />
              </Tab>
            </NavLink>
          </Flex>
        </Tabs>
      </Box>
    </Box>
  );
}
