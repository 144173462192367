import purchrAxios from './purchrAxios';

class AlertsFiredService {
  static async getAlertsByUserId(userId, params = {}) {
    console.log('Fetching alerts for userId:', userId, 'with params:', params);
    try {
      const response = await purchrAxios.get(`/firealerts/alerts`, {
        params: { user_id: userId, ...params },
      });
      console.log('API response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Failed to fetch alerts:', error.response ? error.response.data : error.message);
      throw error;
    }
  }
}

export default AlertsFiredService;

