import React from "react";
import { SavedItem } from "../../components";

const Home = () => {
  const loading = false; // useSelector((state) => state.authReducer.loading);

  return (
    <div>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <div>
          <SavedItem />
        </div>
      )}
    </div>
  );
};

export default Home;
