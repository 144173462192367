import React, { useContext, useState } from 'react';
import { Box, Flex, Text, Button, useToast } from '@chakra-ui/react';
import { PurchrInput } from '../../../components';
import { AuthContext } from '../../../context/auth.context';

import { FirebaseService, UsersService } from '../../../services';

const Password = () => {
  const toast = useToast();

  const { currentUser, logoutUser } = useContext(AuthContext);
  const [resettingPassword, setResettingPassword] = useState(false);

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [reTypeNewPassword, setReTypeNewPassword] = useState('');

  const [updatingSettings, setUpdatingSettings] = useState(false);

  const changePassword = async () => {
    try {
      setResettingPassword(true);
      UsersService.validateUserInfo(
        {
          email: currentUser.email,
          password: newPassword,
          reTypePassword: reTypeNewPassword,
        },
        'CHANGE_PASSWORD'
      );

      const result = await FirebaseService.changePassword({
        currentUser,
        oldPassword,
        newPassword,
      });

      if (!!result?.error) {
        return toast({
          title: result.error,
          status: 'error',
          duration: 2000,
        });
      }

      toast({
        title: 'Password changed successfully.',
        status: 'success',
        duration: 2000,
      });

      logoutUser();
    } catch (error) {
      toast({
        title: error.message,
        status: 'error',
        duration: 2000,
      });
    } finally {
      setResettingPassword(false);
    }
  };

  const resetForm = () => {
    setUpdatingSettings(true);

    setOldPassword('');
    setNewPassword('');
    setReTypeNewPassword('');

    setUpdatingSettings(false);
  };

  return (
    <Box>
      <Flex direction='column'>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='space-between'
          padding={{ base: '0', sm: '0 0 18px 0' }}
          borderBlockEnd={{ base: '0', sm: '1px' }}
          borderBlockEndColor={{ base: '', sm: '#EAECF0' }}
          order={{ base: 2, sm: 1 }}>
          <Box display={{ base: 'none', sm: 'block' }}>
            <Text fontSize='16px' fontWeight='500' color='#101828'>
              Security
            </Text>
            <Text fontSize='12px' fontWeight='400' color='purchr.gray.light'>
              Update your password
            </Text>
          </Box>
          <Box display='flex' gap='10px' mt={{ base: '20px', sm: '0' }} mb={{ base: '24px', sm: '0' }}>
            <Button
              variant='outline'
              w='100%'
              minW='80px'
              color='#6C6C6C'
              isLoading={updatingSettings}
              onClick={resetForm}>
              Reset
            </Button>
            <Button
              variant='solid'
              minW='80px'
              isLoading={resettingPassword}
              onClick={changePassword}>
              Save
            </Button>
          </Box>
        </Box>
        <Flex
          direction='column'
          gap={{ base: '11px', xl: '18px' }}
          mt={{ base: '24px', xl: '21px' }}
          order={{ base: 1, sm: 2 }}>
          <Flex
            gap={{ base: '1px', xl: '82px', '2xl': '170px' }}
            maxW={{ base: '100%', xl: '80%', '2xl': '60%' }}
            direction={{ base: 'column', sm: 'row', xl: 'row' }}>
            <Text minW='135px' color='#6C6C6C' fontSize='12px' fontWeight='500'>
              Old password
            </Text>
            <Flex gap='16px' flex={1}>
              <Box flex={1}>
                <PurchrInput
                  lableProps={{
                    value: '',
                  }}
                  inputProps={{
                    h: '40px',
                    mt: '0',
                    type: 'password',
                    placeholder: 'Enter your old password',
                  }}
                  value={oldPassword}
                  onChange={(event) => setOldPassword(event.target.value)}
                />
              </Box>
            </Flex>
          </Flex>
          <Box display={{ base: 'none', xl: 'block' }}>
            <hr style={{borderColor: '#EAECF0'}} opacity='50%' />
          </Box>
          <Flex
            gap={{ base: '1px', xl: '82px', '2xl': '170px' }}
            maxW={{ base: '100%', xl: '80%', '2xl': '60%' }}
            direction={{ base: 'column', sm: 'row', xl: 'row' }}>
            <Text minW='135px' color='#6C6C6C' fontSize='12px' fontWeight='500'>
              New password
            </Text>
            <Flex gap='16px' flex={1}>
              <Box flex={1}>
                <PurchrInput
                  lableProps={{
                    value: '',
                  }}
                  inputProps={{
                    h: '40px',
                    mt: '0',
                    type: 'password',
                    placeholder: 'Enter your new password',
                  }}
                  value={newPassword}
                  onChange={(event) => setNewPassword(event.target.value)}
                />
              </Box>
            </Flex>
          </Flex>

          <Flex
            gap={{ base: '1px', xl: '82px', '2xl': '170px' }}
            maxW={{ base: '100%', xl: '80%', '2xl': '60%' }}
            direction={{ base: 'column', sm: 'row', xl: 'row' }}>
            <Text minW='135px' color='#6C6C6C' fontSize='12px' fontWeight='500'>
              Re-type new password
            </Text>
            <Flex gap='16px' flex={1}>
              <Box flex={1}>
                <PurchrInput
                  lableProps={{
                    value: '',
                  }}
                  inputProps={{
                    h: '40px',
                    mt: '0',
                    type: 'password',
                    placeholder: 're-type new password',
                  }}
                  value={reTypeNewPassword}
                  onChange={(event) => setReTypeNewPassword(event.target.value)}
                />
              </Box>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Password;
