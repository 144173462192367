import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Box,
  Flex,
  HStack,
  Icon,
  Image,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  Tag,
  TagLabel,
  Text,
  useDisclosure,
  Button,
  Modal,
  ModalContent,
  ModalOverlay,
  Link,
  useToast,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Portal,
  Tooltip,
} from '@chakra-ui/react';

import moment from 'moment';
import verticalLine from '../../assets/vertical-line.png';
import { MdOutlineDone } from 'react-icons/md';
import { IoCloseSharp } from 'react-icons/io5';
import { FaPlus } from 'react-icons/fa';
import { BsTrash3Fill } from 'react-icons/bs';
import { TbBellRinging } from 'react-icons/tb';
import { CiClock2 } from 'react-icons/ci';
import { IoAlert } from 'react-icons/io5';

import style from './index.module.css';
import RetailerIcon from '../../assets/GenericRetailer.svg';
import PurchrSelect from '../PurchrSelect';
import { SavedItemsService } from '../../services';
import { GlobalContext } from '../../context/global.context';
import { uniq, orderBy } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { BsFillCircleFill } from 'react-icons/bs';
import { AuthContext } from '../../context/auth.context';

const filterDays = [1, 3, 7, 14, 30];
const filterDaysDatas = filterDays.map((filterDay) => ({
  value: filterDay,
  label: filterDay === 1 ? 'Daily' : `${filterDay} Days`,
}));

const WatchListItem = ({ savedItem, postDelete, postUpdate }) => {
  const navigate = useNavigate();

  const { allTags, updateAllTags, itemsRemaining } = useContext(GlobalContext);
  const { currentUserMatched } = useContext(AuthContext);
  const toast = useToast();
  const initialFocusRef = useRef();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const isMobileSize = window.innerWidth <= 425;

  const [tagSearchTerm, setTagSearchTerm] = useState('');
  const [filteredTags, setFilteredTags] = useState(
    allTags.filter((tag) => !savedItem.tags.includes(tag))
  );

  const [tags, setTags] = useState(savedItem.tags);

  const [isActivated, setIsActivated] = useState(savedItem.isActivated);
  const [frequency, setFrequency] = useState(savedItem.frequency);

  const [deletingSavedItem, setDeletingSavedItem] = useState(false);

  useEffect(() => {
    setFilteredTags(
      uniq(orderBy(allTags.filter((tag) => !tags.includes(tag))))
    );
  }, [allTags, tags]);

  const changeFrequency = async (newFrequency) => {
    const oldFrequency = frequency;

    try {
      setFrequency(newFrequency);
      const objectToBeUpdated = { frequency: newFrequency };
      await SavedItemsService.update(savedItem._id, objectToBeUpdated);

      if (postUpdate) {
        postUpdate({
          ...savedItem,
          ...objectToBeUpdated,
        });
      }
    } catch (error) {
      setFrequency(oldFrequency);
    }
  };

  const addNewTag = () => {
    if (
      tagSearchTerm &&
      !filteredTags.includes(tagSearchTerm.toLowerCase()) &&
      !tags.includes(tagSearchTerm.toLowerCase())
    ) {
      upsertTag(tagSearchTerm.toLowerCase(), 'ADD', true);
      updateAllTags([tagSearchTerm.toLowerCase()]);

      setTagSearchTerm('');
    }
  };

  const filterTags = (event) => {
    const q = event.target.value || '';

    // We are not going to show tags which are already selected.
    let filterdTags = allTags.filter((tag) => !tags.includes(tag));

    filterdTags = filterdTags.filter((filteredTag) =>
      filteredTag.toLowerCase().includes(q.toLowerCase())
    );
    
    filterdTags = orderBy(uniq(filterdTags));
    setFilteredTags(filterdTags);
    setTagSearchTerm(q);
  };

  const changeActiveStatus = async () => {
    if (itemsRemaining >= 20 && !isActivated === true && !currentUserMatched) {
      toast({
        description: 'Cannot change active status: More than 20 items.',
        status: 'warning',
        duration: 2000,
      });
    } else {
      let newActiveStatus = !isActivated;

      try {
        setIsActivated(newActiveStatus);
        const objectToBeUpdated = {
          isActivated: newActiveStatus,
        };

        if (postUpdate) {
          postUpdate({
            ...savedItem,
            ...objectToBeUpdated,
          });
        }
        await SavedItemsService.update(savedItem._id, objectToBeUpdated);
      } catch (error) {
        setIsActivated(!newActiveStatus);
        const objectToBeUpdated = {
          isActivated: !newActiveStatus,
        };

        if (postUpdate) {
          postUpdate({
            ...savedItem,
            ...objectToBeUpdated,
          });
        }
      }
    }
  };

  const handleDelete = async (savedItemId) => {
    try {
      setDeletingSavedItem(true);
      await SavedItemsService.delete(savedItemId);

      if (postDelete) {
        postDelete(savedItemId);
      }
    } catch (error) {
      toast({
        title: 'Error while deleting saved item. Try again.',
        status: 'error',
        duration: '2000',
      });
    } finally {
      setDeletingSavedItem(false);
      onClose();
    }
  };

  const upsertTag = async (tag, action) => {
    const oldTags = [...tags];

    try {
      const finalTags =
        action === 'REMOVE'
          ? tags.filter((tagValue) => tagValue !== tag)
          : [...tags, tag.toLowerCase()];

      setTags(finalTags);
      setFilteredTags(allTags.filter((tag) => !finalTags.includes(tag)));

      const objectToBeUpdated = { tags: finalTags };

      if (postUpdate) {
        postUpdate({
          ...savedItem,
          ...objectToBeUpdated,
        });
      }
      await SavedItemsService.update(savedItem._id, objectToBeUpdated);
    } catch (error) {
      setTags(oldTags);
      setFilteredTags(allTags.filter((tag) => !oldTags.includes(tag)));
    }
  };

  // Handle click on price drop counter to navigate to AlertHistory with filter
  // const handlePriceDropCounterClick = () => {
  //   localStorage.setItem(
  //     'alertSearch',
  //     JSON.stringify({
  //       productId: savedItem.productInfo._id, // Use productId to match AlertHistoryComponent
  //       name: savedItem.productInfo.name,
  //       thumbnailURL: savedItem.productInfo.thumbnailURL,
  //     })
  //   );
  //   navigate('/alerts');
  // };

  const handlePriceDropCounterClick = () => {
    localStorage.setItem(
      'alertSearch',
      JSON.stringify({
        productId: savedItem.productInfo._id,
        name: savedItem.productInfo.name,
        thumbnailURL: savedItem.productInfo.thumbnailURL,
      })
    );
    navigate('/alerts');
  };

  return (
    <Flex
      position='relative'
      key={savedItem._id}
      gap={{ base: '4px', sm: '4px', lg: '12px' }}
      borderRadius='12px'
      bg={isActivated ? 'white' : '#BABABA'}
      my='8px'
      p='8px 4px 8px 8px'
      alignItems='center'
      justifyContent='center'
    >
      <Flex
        pos='relative'
        alignItems='center'
        width={{ base: '76px', lg: '100px' }}
        height={{ base: '76px', lg: '100px' }}
      >
        <Image
          src={savedItem.productInfo.thumbnailURL}
          alt='product-image'
          width={{ base: '76px', lg: '100px' }}
          height={{ base: '76px', lg: '100px' }}
          borderRadius='4px'
        ></Image>
        <Tooltip
          label='Activate item for price scanning'
          hasArrow
          w='240px'
          placement='right'
        >
          <Box pos="absolute" top='4px' left='6px'>
            <Switch
              colorScheme='green'
              size='sm'
              onChange={changeActiveStatus}
              isChecked={isActivated}
            />
          </Box>
        </Tooltip>
      </Flex>
      <Flex
        gap={{ base: '0', md: '16px', xl: '28px', '2xl': '56px' }}
        justifyContent='space-between'
        flex='1'
      >
        <Box flex='1'>
          <Text
            fontSize={{ base: '9px', md: '12px' }}
            color={isActivated ? 'purchr.gray.light' : '#737373'}
            fontWeight='500'
          >
            {isMobileSize
              ? moment(savedItem.scrapedAt).format('DD/MM/YY @ h:mm a')
              : moment(savedItem.scrapedAt).format('Do MMMM YY @ h:mm a')}
          </Text>

          <Link
            href={savedItem.productInfo.URL}
            fontSize={{ base: '12px', md: '16px', lg: '20px' }}
            color='purchr.blue.dark'
            fontWeight='700'
            textDecoration='underline'
            maxW={{
              base: '106px',
              md: '136px',
              lg: '270px',
              '2xl': '370px',
              '3xl': '570px',
            }}
            overflow='hidden'
            noOfLines='1'
            textOverflow='ellipsis'
            lineHeight='1.7'
            isExternal
          >
            {savedItem.productInfo.name}
          </Link>

          <Box>
            <RadioGroup
              value={frequency}
              display={{ base: 'none', '2xl': 'flex' }}
            >
              <Stack
                direction='row'
                borderRadius='4px'
                border='0.625px solid'
                borderColor='purchr.gray.300'
                fontWeight='700'
                color='purchr.gray.700'
                gap='0'
              >
                {filterDays.map((filterDay) => (
                  <Tooltip
                    label='How often to check for price drops'
                    hasArrow
                    w='250px'
                    placement='right'
                  >
                    <Box
                      borderRight={filterDay !== 30 ? '0.625px solid' : ''}
                      borderColor='purchr.gray.300'
                      p='4px 8px'
                      fontSize='12px'
                      key={filterDay}
                      className={style.radioFill}
                      onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();

                        changeFrequency(filterDay);
                      }}
                    >
                      <Radio
                        value={filterDay}
                        size='sm'
                        spacing='4px'
                        border=' 1px solid #667085 '
                        _checked={{
                          backgroundColor: 'orange',
                          borderStyle: 'none',
                        }}
                      >
                        {filterDay === 1 ? 'Daily' : `${filterDay} Days`}
                      </Radio>
                    </Box>
                  </Tooltip>
                ))}
              </Stack>
            </RadioGroup>

            <Box
              display={{ base: 'flex', '2xl': 'none' }}
              width={{ base: '94px', sm: '130px' }}
            >
              <PurchrSelect
                data={filterDaysDatas}
                className={
                  isActivated
                    ? style.customCssActivated
                    : style.customCssDeactivated
                }
                value={frequency}
                isSearchable={false}
                onChange={(value) => changeFrequency(value.value)}
                leftIcon={BsFillCircleFill}
                flag={true}
                textStyle={{
                  fontSize: isMobileSize ? '10px' : '12px',
                  fontWeight: isMobileSize ? '400' : '700',
                  color: 'purchr.gray.500',
                  cursor: 'pointer',
                  padding: isMobileSize ? '0px' : '0 8px 0 0',
                }}
                optionStyle={{ fontSize: isMobileSize ? '12px' : '16px' }}
                inputLeftIconProps={{
                  fontSize: '10px',
                  color: 'purchr.orange.light',
                }}
                controlStyle={{
                  width: isMobileSize ? '88px' : '130px',
                  minHeight: 'auto',
                  backgroundColor: isActivated ? 'white' : '#9F9F9F',
                }}
                externalStyle={{
                  valueContainer: (provided) => ({
                    ...provided,
                    padding: isMobileSize ? '0px' : '0px 0px 0px 8px',
                  }),
                  input: (provided) => ({
                    ...provided,
                    margin: '0px',
                  }),
                  menu: (provided) => ({
                    ...provided,
                    width: isMobileSize ? '94px' : '130px',
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    margin: '0px 2px 0px 8px',
                  }),
                }}
              />
            </Box>
          </Box>

          <HStack
            spacing='2px'
            mt='4px'
            maxW={{
              base: '106px',
              md: '136px',
              lg: '270px',
              '2xl': '370px',
              '3xl': '570px',
            }}
            overflowY='hidden'
            overflowX='auto'
            style={{ scrollbarWidth: isMobileSize ? 'none' : 'thin' }}
          >
            {(tags || []).map((tag, index) => (
              <Tag
                flex='0 0 auto'
                minH='16px'
                key={index}
                color='#4F0A43'
                bg='rgba(200, 165, 205, 0.30)'
                fontSize={{ base: '8px', md: '9px' }}
                fontWeight='600'
                textTransform='uppercase'
                p={{ base: '2px 4px ', md: '4px' }}
                gap='4px'
                borderRadius='2px'
              >
                <TagLabel lineHeight='normal'>{tag}</TagLabel>
                <Icon
                  as={IoCloseSharp}
                  h='8px'
                  w='8px'
                  cursor='pointer'
                  onClick={() => upsertTag(tag, 'REMOVE')}
                />
              </Tag>
            ))}

            <Popover
              onClose={onClose}
              initialFocusRef={initialFocusRef}
              placement='bottom'
              minW='174px'
            >
              <PopoverTrigger>
                <Tag
                  minH={{ base: '16px', md: '18px' }}
                  minW={{ base: '16px', md: '18px' }}
                  color='#4F0A43'
                  bg='rgba(200, 165, 205, 0.30)'
                  p='0px'
                  justifyContent='center'
                  borderRadius='2px'
                  verticalAlign={{ base: 'middle', md: 'baseline' }}
                  cursor='pointer'
                >
                  <Icon
                    as={FaPlus}
                    padding={{ base: '0', md: '2px' }}
                    h={{ base: '8px', md: '12px' }}
                    w={{ base: '8px', md: '12px' }}
                  />
                </Tag>
              </PopoverTrigger>
              <Portal>
                <PopoverContent
                  w='174px'
                  _focusVisible={{ outline: 'none', boxShadow: 'none' }}
                >
                  <Box
                    m='0px'
                    p='8px 10px'
                    bg='purchr.brown.dark'
                    color='#fff'
                    display='flex'
                    gap='6px'
                    alignItems='center'
                  >
                    <Box mt='2px'>
                      <Text
                        fontSize='8px'
                        fontStyle='italic'
                        fontWeight='500'
                        ps='8px'
                      >
                        Add new tag
                      </Text>
                      <Input
                        size='xs'
                        type='search'
                        value={tagSearchTerm}
                        onChange={filterTags}
                        placeholder='New tag goes here'
                        paddingX='4px'
                        bg='#fff'
                        color='purchr.blue.dark'
                        borderRadius='8px'
                        _placeholder={{ color: 'purchr.blue.dark' }}
                        autoFocus
                      />
                    </Box>
                    <Icon
                      as={MdOutlineDone}
                      onClick={addNewTag}
                      cursor='pointer'
                    />
                  </Box>
                  <Box maxHeight='260px' overflowY='auto' onClick={onClose}>
                    {filteredTags.map((tag, index) => (
                      <Text
                        cursor='pointer'
                        key={index}
                        _hover={{ color: 'white', bg: 'purchr.brown.dark' }}
                        pl='8px' 
                        onClick={() => {
                          upsertTag(tag, 'ADD');
                          onClose();
                        }}
                      >
                        {tag}
                      </Text>
                    ))}
                  </Box>
                </PopoverContent>
              </Portal>
            </Popover>
          </HStack>
        </Box>

        <Flex
          justifyContent='flex-end'
          alignItems={{ base: 'center', xl: 'flex-start' }}
          gap={{ base: '4px', '2xl': '12px' }}
          pt={{ base: '4px', lg: '16px' }}
          flexDir={{ base: 'column-reverse', xl: 'row' }}
        >
          <Box py='4px'>
            <Image
              src={
                savedItem.productInfo.retailerIcon != null
                  ? savedItem.productInfo.retailerIcon
                  : RetailerIcon
              }
              alt='RetailerIcon'
              display='inline'
              h='20px'
              w='20px'
            />
          </Box>
          <Tooltip
            label='Price drop found counter'
            hasArrow
            w='190px'
            placement='right'
          >
            <Tag
              p='8px'
              gap='8px'
              bg='purchr.gray.bg'
              borderRadius='4px'
              color='purchr.gray.light'
              pos='relative'
              cursor='pointer'
              display={{ base: 'none', xl: 'inline-flex' }}
              onClick={handlePriceDropCounterClick} // Updated handler
            >
              <Icon
                as={IoAlert}
                bg='#FF8F28'
                borderRadius='16px'
                p='2px'
                transform='rotate(21deg)'
                pos='absolute'
                bottom='24px'
                left='40px'
                color='white'
                fontSize='12px'
                fontWeight='700'
              />
              <Icon as={TbBellRinging} h='12px' w='12px' />

              <TagLabel fontSize='12px' fontWeight='700'>
                {savedItem.alertCounter}
              </TagLabel>
            </Tag>
          </Tooltip>

          <Icon
            as={BsTrash3Fill}
            color={{ base: 'purchr.blue.dark', sm: '#A7A7A7' }}
            borderRadius={{ base: '4px', md: '6px' }}
            h={{ base: '20px', xl: '28px' }}
            w={{ base: '20px', xl: '28px' }}
            p={{ base: '4px', xl: '6px' }}
            bg='purchr.gray.bg'
            cursor='pointer'
            onClick={() => onOpen()}
          />
        </Flex>
      </Flex>

      <Flex
        flexDirection='column'
        alignSelf='normal'
        alignItems='center'
        justifyContent='space-between'
      >
        <Box
          h='15px'
          w='30px'
          bg='purchr.gray.bg'
          borderRadius=' 0 0 150px 150px'
          marginTop='-8px'
        ></Box>
        <Image src={verticalLine} />
        <Box
          h='15px'
          w='30px'
          bg='purchr.gray.bg'
          borderRadius='150px 150px 0 0'
          marginBottom='-8px'
        ></Box>
      </Flex>

      <Flex flexDir='column' justifyContent='center' gap='8px' pr='8px'>
        <Tooltip
          label='Original price when added'
          hasArrow
          w='220px'
          placement='right'
        >
          <Box width={{ base: '94px', xl: '176px' }}>
            <Tag
              py='8px'
              border='1px dashed #DDD'
              borderRadius='8px'
              color='purchr.gray.light'
              alignItems='center'
              gap='8px'
              px={{ base: '4px', xl: '28px' }}
              w='100%'
              justifyContent='center'
              bg='purchr.gray.bg'
            >
              <Icon
                as={CiClock2}
                display={{ base: 'none', xl: 'block' }}
                h={{ base: '10px', md: '18px' }}
                w={{ base: '10px', md: '18px' }}
              />
              <Text fontSize={{ base: '12px', xl: '18px' }} fontWeight='700'>
                ${parseFloat(savedItem.originalPrice || 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
              </Text>
            </Tag>
          </Box>
        </Tooltip>
        <Tooltip
          label='Most recent sale price found'
          hasArrow
          w='220px'
          placement='right'
        >
          <Box width={{ base: '94px', xl: '176px' }}>
          {!savedItem.lastAlertPrice || (savedItem.lastAlertPrice >= savedItem.originalPrice) ? (
              <Tag
                py='8px'
                border='0.965px dashed'
                borderRadius='8px'
                borderColor='purchr.gray.light'
                alignItems='center'
                gap='8px'
                w='100%'
                justifyContent='center'
                px={{ base: '4px', xl: '28px' }}
                bg='purchr.gray.light'
              >
                <Text
                  fontSize={{ base: '12px', xl: '16px' }}
                  fontWeight='700'
                  color='white'
                >
                  No Sale Found
                </Text>
              </Tag>
            ) : (
              <Tag
                py='8px'
                border='0.965px dashed'
                borderRadius='8px'
                borderColor={
                  isActivated ? 'purchr.orange.light' : 'purchr.gray.light'
                }
                alignItems='center'
                gap='8px'
                w='100%'
                justifyContent='center'
                px={{ base: '4px', xl: '28px' }}
                bg={isActivated ? 'purchr.orange.light' : 'purchr.gray.light'}
              >
                <Icon
                  as={TbBellRinging}
                  color='rgba(255, 255, 255, 0.40)'
                  h={{ base: '10px', md: '18px' }}
                  w={{ base: '10px', md: '18px' }}
                />
                <Text
                  fontSize={{ base: '12px', xl: '18px' }}
                  fontWeight='700'
                  color='white'
                >
                  ${parseFloat(savedItem.lastAlertPrice || 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                </Text>
              </Tag>
            )}
          </Box>
        </Tooltip>
      </Flex>

      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(5px)' />
        <ModalContent
          maxW='360px'
          alignItems='center'
          p='24px'
          borderRadius='12px'
          gap='32px'
        >
          <Flex flexDir='column' alignItems='center' gap='20px'>
            <Box
              p='6px 8px 2px 8px'
              bg='#DCCCE3'
              border='10px solid #FBF2FF'
              borderRadius='50%'
            >
              <Icon as={BsTrash3Fill} color='#4F0A43' w='22px' h='24px' />
            </Box>
            <Flex flexDir='column' alignItems='center' gap='8px'>
              <Text color='purchr.gray.900' fontWeight='600' fontSize='18px'>
                Delete item?
              </Text>
              <Text color='purchr.gray.500' fontWeight='400' fontSize='14px'>
                Are you sure you want to delete this item?
              </Text>
            </Flex>
          </Flex>
          <Flex gap='12px'>
            <Button
              variant='outline'
              w='150px'
              p='10px 18px'
              color='purchr.gray.700'
              fontSize='16px'
              fontWeight='600'
              onClick={() => onClose()}
            >
              Cancel
            </Button>
            <Button
              variant='solid'
              w='150px'
              p='10px 18px'
              fontSize='16px'
              fontWeight='600'
              onClick={() => {
                handleDelete(savedItem._id);
              }}
              isLoading={deletingSavedItem}
            >
              Confirm
            </Button>
          </Flex>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default WatchListItem;




