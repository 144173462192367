import React from 'react';
import Select, { components } from 'react-select';
import { Box, Flex, Text, Icon } from '@chakra-ui/react';
import { CircleFlag } from 'react-circle-flags';

function PurchrSelect({
  boxProps,
  data,
  value,
  onChange,
  leftIcon,
  flag,
  handleMulti,
  textStyle = {},
  optionStyle = {},
  lableProps = {},
  inputLeftIconProps = {},
  controlStyle = {},
  externalStyle,
  ...props
}) {
  const Option = (props) => (
    <components.Option {...props}>
      <Flex alignItems='center' gap='8px'>
        {props.data.icon && (
          <CircleFlag countryCode={props.data.icon} width='20px' />
        )}
        <Text fontSize='16px' fontWeight='400' color='#1a202c' {...optionStyle}>
          {props.data.label}
        </Text>
      </Flex>
    </components.Option>
  );

  const SingleValue = ({ children, ...props }) => {
    const selectedCountry = data?.find((country) => country.label === children);
    return (
      <components.SingleValue {...props}>
        <Flex alignItems='center' gap='20px'>
          {leftIcon && flag && (
            <Icon
              as={leftIcon}
              fontSize='20px'
              color='purchr.gray.light'
              {...inputLeftIconProps}
            />
          )}
          {selectedCountry && selectedCountry.icon && (
            <CircleFlag countryCode={selectedCountry.icon} width='20px' />
          )}
          <Text fontSize='14px' fontWeight='400' color='#1a202c' {...textStyle}>
            {flag ? (children === 1 ? 'Daily' : `${children} Days`) : children}
          </Text>
        </Flex>
      </components.SingleValue>
    );
  };

  const Input = (props) => (
    <Box>
      {leftIcon ? (
        <Flex alignItems='center' paddingLeft='12px'>
          <Icon
            as={leftIcon}
            fontSize='20px'
            color='purchr.gray.light'
            {...inputLeftIconProps}
          />

          <components.Input {...props} />
        </Flex>
      ) : (
        <components.Input {...props} />
      )}
    </Box>
  );

  return (
    <Box {...boxProps}>
      {lableProps.value ? (
        <Text
          fontSize='12px'
          fontWeight={500}
          color='purchr.gray.700'
          {...lableProps}
        >
          {lableProps.value}
        </Text>
      ) : null}
      <Select
        value={!handleMulti ? { value: value, label: value } : value}
        options={data}
        onChange={onChange}
        menuPlacement='auto'
        minMenuHeight='100px'
        styles={{
          singleValue: (provided) => ({
            ...provided,
            display: 'flex',
            alignItems: 'center',
            order: 2,
          }),
          indicatorSeparator: (provided) => ({
            ...provided,
            display: 'none',
          }),
          option: (provided, state) => ({
            ...provided,
            color: '#1e2022',
            backgroundColor: state.isSelected ? '#e2e8ee' : 'white',
            cursor: 'pointer',
            padding: '8px 0 8px 20px',
            margin: '4px 0',
            borderRadius: '4px',
            overflow: 'hidden',
            '&:hover': { backgroundColor: '#e2e8ee' },
          }),
          control: (provided) => ({
            ...provided,
            ...controlStyle,
            borderRadius: '6px',
            borderColor: '#d0d5dd',
          }),
          menuList: (provided) => ({
            ...provided,
            padding: '4px 8px',
          }),

          ...externalStyle,
        }}
        components={{
          Option,
          SingleValue,
          Input,
        }}
        {...props}
      />
    </Box>
  );
}

export default PurchrSelect;
