import {
  Tab,
  Tabs,
  Text,
  TabPanel,
  TabList,
  TabPanels,
  Flex,
  Box,
  Image,
} from '@chakra-ui/react';
import React, { Fragment, useEffect, useState } from 'react';
import MyDetails from '../Settings/MyDetails';
import Password from '../Settings/Password';
import Notification from '../Settings/Notifications';
import { ContentHeader } from '../../components';
import { IoMdPerson } from 'react-icons/io';
import { BiSolidLock } from 'react-icons/bi';
import { TbBellRinging } from 'react-icons/tb';
import { useLocation } from 'react-router';
import Feedback from '../Settings/Feedback';
import FeedbackIcon from '../../assets/feedback-icon.svg'

const MyAccount = () => {
  const isMobileSize = window.innerWidth <= 425;
  
  const params = useLocation();

  const [tabIndex, setTabIndex] = useState(0);

  const tabs = [
    {
      title: 'My details',
      icon: <IoMdPerson />,
    },
    {
      title: 'Password',
      icon: <BiSolidLock />,
    },
    {
      title: 'Notification',
      icon: <TbBellRinging />,
    }, {
      title: 'Feedback',
      icon: <Image src={FeedbackIcon} />
    }
    // {
    //   title: 'Billing',
    //   icon: <PiCreditCardFill />,
    //   disabled: true,
    // },
  ];

  const tabsContent = [
    {
      component: <MyDetails />,
    },
    {
      component: <Password />,
    },
    {
      component: <Notification />,
    },
    {
      component: <Feedback />
    }
  ];

  useEffect(() => {
    if (params.hash.includes('settings')) {
      return setTabIndex(2);
    } else if (params.hash.includes('feedback')) {
      return setTabIndex(3);
    } else if (params.hash.includes('billing')) {
      return setTabIndex(4);
    }

    setTabIndex(0);
  }, [params]);

  const handleTabsChange = (index) => {
    setTabIndex(index);
  };


  const getTab = (tabInfo, index) => {
    return (
      <Tab
        key={index}
        fontSize='14px'
        padding={{ base: '12px', sm: '0 4px 16px 4px' }}
        fontWeight='500'
        flex='1'
        isDisabled={tabInfo.disabled}
        _disabled={{ cursor: 'pointer', opacity: '0.4' }}
        _active={{ background: 'transparent' }}
        _focusVisible={{ outline: 'none' }}
        _selected={{
          color: 'purchr.brown.dark',
          borderBlockEnd: '2px',
          borderBlockEndColor: 'purchr.brown.dark',
        }}>
        {!isMobileSize ? (
          <Text>{tabInfo.title}</Text>
        ) : (
          <Text fontSize='x-large'>{tabInfo.icon}</Text>
        )}
      </Tab>
    );
  };

  return (
    <Fragment>
      <Flex gap={{ base: '0', sm: '4px' }} flexDirection='column'>
        <ContentHeader
          title='Your Account'
          description='Your personal settings'
          headingStyle={{
            color: 'purchr.brown.dark',
          }}
          descriptionStyle={{
            color: '#969696',
          }}
        />
      </Flex>

      <Tabs index={tabIndex} onChange={handleTabsChange}>
        <TabList
          color='purchr.gray.light'
          display='flex'
          gap={{ base: '11px', sm: '17px' }}
          maxW={{ base: '100%', xl: '500px' }}
          borderBlockEnd={{ base: '0', sm: '1px' }}
          borderBlockEndColor={{ base: '', sm: '#EAECF0' }}
          mt={{ base: '11px', sm: '32px' }}>
          {tabs.map((tabInfo, index) => {
            return getTab(tabInfo, index);
          })}
        </TabList>

        <TabPanels>
          {tabsContent.map((tab) => {
            return (
              <Box>
                <TabPanel
                  style={{ scrollbarWidth: 'none' }}
                  overflowY='auto'
                  height={{
                    base: `calc(${window.innerHeight}px - 289px)`,
                    lg: `calc(${window.innerHeight}px - 264px)`,
                  }}
                  p='0'
                  mt={{ base: '0', sm: '20px' }}
                  display='flex'
                  flexDirection='column'>
                  <Box order={{ base: 1, sm: 2 }}>{tab.component}</Box>
                </TabPanel>
              </Box>
            );
          })}
        </TabPanels>
      </Tabs>
    </Fragment>
  );
};

export default MyAccount;
