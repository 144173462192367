import { useState, useEffect, useContext } from 'react';
import { Box, Flex, Spinner, Checkbox } from '@chakra-ui/react';
import ContentHeader from '../ContentHeader';
import SearchItems from '../SearchItems';
import WatchListItem from '../WatchListItem';
import { SavedItemsService } from '../../services';
import { flattenDeep, uniq, orderBy, cloneDeep } from 'lodash';

import { GlobalContext } from '../../context/global.context';
import style from './index.module.css';
import PurchrSelect from '../PurchrSelect';
import ItemNotFound from '../ItemNotFound';

const filterItemDatas = [
  // 'Filter XYZ',
  // 'Filter ABC',
  // 'Filter PQR',
  // 'Filter ASD',
];

const SavedItem = () => {
  const isMobileSize = window.innerWidth <= 425;
  const isTabletSize = window.innerWidth <= 1024;

  const {
    allTags,
    updateAllTags,
    allSavedItems,
    initializeSavedItems,
    deleteSavedItem,
    updateSavedItem,
  } = useContext(GlobalContext);

  let allTagsObjectArray = uniq(orderBy(allTags)).map((value) => ({
    value,
    label: value,
  }));

  let [savedItemsToShow, setSavedItemsToShow] = useState(null);

  const [fetchingSavedItems, setFetchingSavedItems] = useState(false);

  const [isActivated, setIsActivated] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedTags, setSelectedTags] = useState([]);

  savedItemsToShow = orderBy(savedItemsToShow, ['scrapedAt'], ['desc']);

  useEffect(() => {
    const fetchSavedItems = async () => {
      setFetchingSavedItems(true);

      const savedItems = await SavedItemsService.all();

      initializeSavedItems(savedItems);

      let savedItemsToShow = cloneDeep(savedItems || []);

      let searchQuery = localStorage.getItem('search');
      if (searchQuery) {
        searchQuery = searchQuery.toLowerCase();
        setSearchQuery(searchQuery);
        savedItemsToShow = savedItemsToShow.filter((savedItemToShow) => {
          const productInfo = savedItemToShow.productInfo;

          return (
            productInfo.name.toLowerCase().includes(searchQuery) ||
            productInfo.retailer.toLowerCase().includes(searchQuery) ||
            savedItemToShow.tags.some((tag) =>
              tag.toLowerCase().includes(searchQuery)
            )
          );
        });

        localStorage.removeItem('search');
      }
      setSavedItemsToShow(savedItemsToShow);
      collectTags(savedItems);

      setFetchingSavedItems(false);
    };

    fetchSavedItems();
  }, []);

  const collectTags = (items) => {
    let tags = items.map((item) => {
      return item.tags;
    });

    tags = flattenDeep(tags);
    tags = uniq(tags);
    tags = orderBy(tags);

    updateAllTags(tags.map((tag) => tag.toLowerCase()));
  };

  const handleDelete = async (savedItemId) => {
    const clonedSavedItems = cloneDeep(allSavedItems || []);
    let indexToBeDeleted = clonedSavedItems.findIndex(
      (savedItem) => savedItem._id === savedItemId
    );

    clonedSavedItems.splice(indexToBeDeleted, 1);

    const clonedSavedItemsToShow = cloneDeep(savedItemsToShow || []);
    indexToBeDeleted = clonedSavedItemsToShow.findIndex(
      (savedItem) => savedItem._id === savedItemId
    );

    clonedSavedItemsToShow.splice(indexToBeDeleted, 1);
    setSavedItemsToShow(clonedSavedItemsToShow);

    deleteSavedItem(savedItemId);
  };

  const handleUpdate = async (updatedSavedItem) => {
    const clonedSavedItems = cloneDeep(allSavedItems || []);
    clonedSavedItems.forEach((savedItem) => {
      if (savedItem._id === updatedSavedItem._id) {
        savedItem = updatedSavedItem;
      }
    });

    updateSavedItem(updatedSavedItem);

    const clonedSavedItemsToShow = cloneDeep(savedItemsToShow || []);
    clonedSavedItemsToShow.forEach((savedItem) => {
      if (savedItem._id === updatedSavedItem._id) {
        savedItem = updatedSavedItem;
      }
    });

    setSavedItemsToShow(clonedSavedItemsToShow);
  };

  useEffect(() => {
    const handleAllFilter = () => {
      let clonedAllItems = cloneDeep(allSavedItems || []);
      const tagsToBeFiltered = selectedTags.map((data) => data.value);

      clonedAllItems = clonedAllItems.filter((clonedSavedItem) => {
        const productInfo = clonedSavedItem.productInfo;
        return (
          (clonedSavedItem.isActivated || !isActivated) &&
          tagsToBeFiltered.every((data) =>
            clonedSavedItem.tags.some((tag) =>
              tag.toLowerCase().includes(data.toLowerCase())
            )
          ) &&
          (productInfo.name.toLowerCase().includes(searchQuery) ||
            productInfo.retailer.toLowerCase().includes(searchQuery))
        );
      });

      setSavedItemsToShow(clonedAllItems);
    };
    handleAllFilter();
  }, [isActivated, searchQuery, selectedTags, allSavedItems]);

  return (
    <Box>
      <ContentHeader
        title='Watch list'
        description='Review all your saved items, set price change alerts'
        headingStyle={{
          color: 'purchr.brown.dark',
          fontSize: { base: '32px', sm: '44px' },
          fontWeight: '700',
        }}
        descriptionStyle={{
          fontWeight: '400',
          fontSize: '14px',
          color: '#C0C0C0',
          display: { base: 'none', md: 'block' },
        }}
      />

      <SearchItems
        filterItemDatas={filterItemDatas}
        handleSearch={(q) => setSearchQuery(q.toLowerCase())}
        rightChildren={
          <PurchrSelect
            value={selectedTags}
            className={style.customCss}
            data={allTagsObjectArray}
            onChange={setSelectedTags}
            isMulti
            handleMulti={true}
            isSearchable={false}
            placeholder='Select Tag'
            controlStyle={{
              minHeight: 'auto',
              height: '26px',
              width: isTabletSize ? '248px' : '412px',
              '&:hover': { borderColor: '#b5b5b5' },
            }}
            externalStyle={{
              valueContainer: (provided) => ({
                ...provided,
                maxWidth: isTabletSize ? '186px' : '350px',
                overflowX: 'auto',
                scrollbarWidth: 'thin',
                flexWrap: 'nowrap',
                padding: '0px 8px',
              }),
              indicatorsContainer: (provided) => ({
                ...provided,
                height: '26px',
              }),
              multiValue: (provided) => ({
                ...provided,
                flexShrink: '0',
                fontSize: '12px',
              }),
              placeholder: (provided) => ({
                ...provided,
                fontSize: '12px',
                fontWeight: '600',
                color: '#6C6C6C',
              }),
            }}
          />
        }>
        <Checkbox
          className={style.checkboxRound}
          isChecked={isActivated}
          size='sm'
          fontWeight='700'
          color='purchr.gray.700'
          padding={{ base: '4px 32px 4px 8px', lg: '4px 24px 4px 8px ' }}
          border='0.7px solid'
          borderColor='purchr.gray.300'
          borderRadius='6px'
          spacing='8px'
          boxShadow='0px 0.7px 1.4px 0px rgba(16, 24, 40, 0.05);'
          bg='white'
          height='26px'
          _checked={{ bg: '#4F0A43', color: 'white', size: 'xs' }}
          onChange={() => setIsActivated(!isActivated)}>
          Active
        </Checkbox>
      </SearchItems>

      <Box
        mt={{ base: '8px', sm: '20px' }}
        height={{
          base: `calc(${window.innerHeight}px - 350px)`,
          lg: `calc(${window.innerHeight}px - 300px)`,
        }}
        overflowY='auto'
        style={{ scrollbarWidth: isMobileSize ? 'none' : 'thin' }}>
        {fetchingSavedItems || !allTags ? (
          <Flex justifyContent='center' alignItems='center' height='100%'>
            <Spinner size='md' />
          </Flex>
        ) : !savedItemsToShow || !savedItemsToShow.length ? (
          <ItemNotFound />
        ) : (
          savedItemsToShow.map((savedItem) => (
            <WatchListItem
              key={savedItem._id}
              savedItem={savedItem}
              postDelete={handleDelete}
              postUpdate={handleUpdate}
            />
          ))
        )}
      </Box>
    </Box>
  );
};
export default SavedItem;
