import purchrAxios from './purchrAxios';

export default class EmailService {
  static async forgotPassword(email) {
    console.log({ email });
    const response = await purchrAxios.post(
      `/email/forgot-password`,
      { email },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    console.log('forgot : ', response);

    return response.data;
  }

  static async sendVerificationEmail() {
    const response = await purchrAxios.post(`/email/verify`, {}, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    console.log('send :', response);

    return response.data;
  }
}
