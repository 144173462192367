import {
  Box,
  Image,
  Menu,
  MenuList,
  MenuButton,
  Text,
  Icon,
  HStack,
  Flex,
  useDisclosure,
  Tooltip,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { FaChevronDown, FaCheck } from 'react-icons/fa';
import Vector from '../../assets/Vector.jpg';
import { uniqBy } from 'lodash';

const AlertHistoryDropDown = ({
  currentPageAlertItems, // Now receives allAlertItems
  selectedItem,
  handleSelectItem,
  handleClearSelection,
}) => {
  const [isDropDrownOpen, setIsDropDrownOpen] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Ensure currentPageAlertItems is an array and filter unique items by productId
  const uniqueItems = uniqBy(currentPageAlertItems || [], 'productId');

  return (
    <Box>
      <Menu isOpen={isOpen} onClose={onClose} onOpen={onOpen}>
        <MenuButton
          w='200px' // Increased width to accommodate longer names
          p='8px 12px'
          borderRadius='4px'
          border='1px solid #E6E6E6' // Match the grey border from the image
          bg='white'
          fontSize='14px'
          color='purchr.gray.700'
          fontWeight='400'
          _hover={{ bg: '#F5F5F5' }} // Subtle hover effect
          _focus={{ boxShadow: 'outline', outline: 'none' }} // Improve focus styling
        >
          <Flex alignItems='center' justifyContent='space-between' gap='12px'> {/* Increased gap */}
            {selectedItem ? (
              <HStack gap='8px' maxWidth='160px'> {/* Increased maxWidth */}
                <Image
                  src={selectedItem.thumbnailURL || 'default-image.png'}
                  w='20px'
                  h='20px'
                  borderRadius='2px'
                  objectFit='cover'
                />
                <Tooltip label={selectedItem.name} hasArrow placement='top'>
                  <Text
                    overflowX='hidden'
                    whiteSpace='nowrap'
                    textOverflow='ellipsis'
                    fontWeight='500' // Slightly bolder for readability
                  >
                    {selectedItem.name || 'Unnamed Item'}
                  </Text>
                </Tooltip>
              </HStack>
            ) : (
              <Text>Select Item</Text>
            )}
            <Icon as={FaChevronDown} w='12px' h='12px' color='purchr.gray.700' />
          </Flex>
        </MenuButton>

        <MenuList
          p='0px'
          borderRadius='8px'
          pos='relative'
          borderColor='#E6E6E6'
          boxShadow='0px 4px 8px rgba(0, 0, 0, 0.1)'
          maxW='300px'
          bg='white' // Ensure background matches the button
        >
          <HStack
            p='8px 12px'
            justifyContent='space-between'
            color='purchr.gray.light'
            fontWeight='400'
            pos='sticky'
            top='0px'
            bg='white'
            borderBottom='1px solid #E6E6E6'
          >
            <Text
              fontSize='12px'
              cursor='pointer'
              onClick={handleClearSelection}
            >
              {`Clear selection >`}
            </Text>
            <Image
              src={Vector}
              w='12px'
              h='12px'
              cursor='pointer'
              onClick={onClose}
            />
          </HStack>

          <Box maxH='200px' overflowY='auto' style={{ scrollbarWidth: 'thin' }}>
            {uniqueItems.length > 0 ? (
              uniqueItems.map((item, index) => {
                const isSelected = selectedItem?.productId === item.productId;

                return (
                  <Box
                    key={index}
                    cursor='pointer'
                    minH='40px'
                    p='8px 12px'
                    display='flex'
                    justifyContent='space-between'
                    alignItems='center'
                    bg={isSelected ? 'purchr.brown.dark' : '#fff'}
                    color={isSelected ? '#fff' : 'purchr.gray.700'}
                    _hover={{ bg: 'purchr.brown.light' }}
                    onClick={() => {
                      handleSelectItem(item);
                      onClose(); // Close the dropdown after selection
                    }}
                  >
                    <Box display='flex' gap='8px' alignItems='center'>
                      <Image
                        src={item.thumbnailURL || 'default-image.png'}
                        w='32px'
                        h='32px'
                        borderRadius='2px'
                        objectFit='cover'
                      />
                      <Tooltip label={item.name} hasArrow placement='top'>
                        <Text
                          fontSize='14px'
                          fontWeight='400'
                          maxWidth='200px'
                          overflowX='hidden'
                          whiteSpace='nowrap'
                          textOverflow='ellipsis'
                          title={item.name}
                        >
                          {item.name || 'Unnamed Item'}
                        </Text>
                      </Tooltip>
                    </Box>
                    {isSelected && (
                      <Box>
                        <Icon as={FaCheck} h='16px' w='16px' />
                      </Box>
                    )}
                  </Box>
                );
              })
            ) : (
              <Box p='8px 12px'>
                <Text fontSize='14px'>No items available</Text>
              </Box>
            )}
          </Box>
        </MenuList>
      </Menu>
    </Box>
  );
};

export default AlertHistoryDropDown;
