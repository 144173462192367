import { Text, HStack, Tag, TagLabel } from '@chakra-ui/react';
import React from 'react';
const SuccessBadge = ({ description, btnText, descriptionStyle, ...props }) => {
  return (
    <HStack spacing={4} {...props}>
      <Tag
        colorScheme='green'
        p='4px 4px 4px 12px'
        borderRadius='full'
        variant='solid'
        bg='#ECFDF3'
        color='#027A48'
        gap='8px'>
        <TagLabel fontWeight='500' fontSize='12px' color='#027A48'  {...descriptionStyle}>
          {description}
        </TagLabel>
        <Text
          bg='#039855'
          color='white'
          p='2px 8px'
          fontWeight='500'
          fontSize='12px'
          borderRadius='30px'>
          {btnText}
        </Text>
      </Tag>
    </HStack>
  );
};
export default SuccessBadge;
