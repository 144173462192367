import React, { useContext, useState, useEffect } from 'react';
import {
  Box,
  Grid,
  GridItem,
  Flex,
  Image,
  Card,
  CardBody,
  Text,
  Button,
  useToast,
} from '@chakra-ui/react';

import HeroImage from '../../assets/Hero.webp';
import LogoImage from '../../assets/logo.svg';

import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';

import { BackGroundUI, ContentHeader, PurchrInput } from '../../components';
import { AuthContext } from '../../context/auth.context';
import { EmailService, FirebaseService } from '../../services';

export default function ForgotPassword() {
  const toast = useToast();
  const history = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const { authPending, error, toggleError } =
    useContext(AuthContext);

  if (error) {
    toggleError();
  }

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [reTypePassword, setReTypePassword] = useState('');

  const [sendingEmail, setSendingEmail] = useState('');
  const [verificationResult, setVerificationResult] = useState(null);

  useEffect(() => {
    const code = searchParams?.get('oobCode');
    if (code) {
      verifyCode(code);
    }
  }, [searchParams]);

  const verifyCode = async (code) => {
    const verificationResult = await FirebaseService.verifyCode(code);
    setVerificationResult(verificationResult);
  };

  const sendResetLink = async () => {
    try {
      setSendingEmail(true);

      if (!email) {
        toast({
          title: 'Enter valid email',
          status: 'error',
          duration: '2000',
        });
        setSendingEmail(false);
        return;
      }

      await EmailService.forgotPassword(email)
      setEmail('')

      toast({
        title: 'Password reset email sent. Please check your inbox.',
        status: 'success',
        duration: '2000',
      });
      setSendingEmail(false);
    } catch (error) {
      setSendingEmail(false);

      let message = error.message;
      if (error.message.includes('auth/user-not-found')) {
        message = 'User not found';
      }

      if (error.message.includes('auth/invalid-email')) {
        message = 'Enter valid email';
      }

      toast({
        title: message,
        status: 'error',
        duration: '2000',
      });
    }
  };

  const confirmPasswordReset = async () => {
    try {
      const code = searchParams?.get('oobCode');
      if (verificationResult?.operation === 'PASSWORD_RESET') {
        await FirebaseService.confirmPasswordReset(code, reTypePassword);
        toast({
          title: 'Password changed successfully',
          status: 'success',
          duration: '2000',
        });

        history('/');
      }
    } catch (error) {
      setSendingEmail(false);

      let message = error.message;
      if (error.message.includes('auth/user-not-found')) {
        message = 'User not found';
      } else if (
        error.message.includes('auth/expired-action-code') ||
        error.message.includes('auth/invalid-action-code')
      ) {
        message = 'Verification code is expired or invalid.';
      } else if (error.message.includes('auth/user-disabled')) {
        message = 'User is disabled.';
      } else if (error.message.includes('auth/weak-password')) {
        message = 'Enter strong password.';
      }

      toast({
        title: message,
        status: 'error',
        duration: '2000',
      });
    }
  };

  return (
    <Box
      bg='purchr.bgHero'
      h={`${window.innerHeight}px`}
      p={{ base: '0', sm: '0px', xl: '0 40px 0 40px' }}>
      <BackGroundUI />
      <Grid
        h='100%'
        templateColumns='repeat(10, 1fr)'
        gap='16px'
        alignItems='center'>
        <GridItem colSpan={{ base: 10, xl: 5, '2xl': 4 }}>
          <Box>
            <Flex h='100%' direction='column' alignItems='center'>
              <Image src={LogoImage} height='100px' mb='24px' zIndex={1} />
              <Card
                p={{ base: '48px', sm: '90px 75px' }}
                borderRadius='42px'
                w={{ base: 'auto', sm: '400px' }}>
                <CardBody p='0px' display='flex' flexDirection='column'>
                  <ContentHeader
                    size='small'
                    title='Reset password'
                    description='Oops, lost your password? Reset it here!'
                    descriptionStyle={{ mt: '8px' }}
                    subTitle={verificationResult?.data?.email || ''}
                  />
                  {!searchParams?.get('oobCode') ? (
                    <Box>
                      <PurchrInput
                        boxProps={{
                          mt: '36px',
                        }}
                        lableProps={{
                          value: 'Email',
                        }}
                        inputProps={{
                          type: 'email',
                          placeholder: 'Ex. peter@email.com',
                        }}
                        value={email}
                        onChange={(event) => {
                          setEmail(event.target.value);
                          setSearchParams({ code: event.target.value });
                        }}
                      />
                      <Box
                        mt='36px'
                        display='flex'
                        justifyContent='space-between'>
                        <NavLink to='/login'>
                          <Button variant='outline' h='auto' p='8px 20px'>
                            Cancel
                          </Button>
                        </NavLink>
                        <Button
                          variant='solid'
                          w='auto'
                          p='8px 20px'
                          isLoading={sendingEmail}
                          onClick={sendResetLink}>{`Confirm >`}</Button>
                      </Box>
                    </Box>
                  ) : (
                    <Box display='flex' flexDirection='column'>
                      <PurchrInput
                        boxProps={{
                          mt: '36px',
                        }}
                        lableProps={{
                          value: 'New password',
                        }}
                        inputProps={{
                          type: 'password',
                          placeholder: 'Enter New password',
                        }}
                        value={password}
                        onChange={(event) => {
                          setPassword(event.target.value);
                        }}
                      />
                      <PurchrInput
                        boxProps={{
                          mt: '16px',
                        }}
                        lableProps={{
                          value: 'Re-type new password',
                        }}
                        inputProps={{
                          type: 'password',
                          placeholder: 'Re-Enter New Password',
                        }}
                        value={reTypePassword}
                        onChange={(event) => {
                          setReTypePassword(event.target.value);
                        }}
                      />
                      <Box alignSelf='end'>
                        <Button
                          variant='solid'
                          mt='28px'
                          w='auto'
                          p='8px 12px'
                          isLoading={authPending}
                          onClick={(event) => confirmPasswordReset()}>
                          {`Change password >`}
                        </Button>
                      </Box>
                    </Box>
                  )}
                </CardBody>
              </Card>
            </Flex>
          </Box>
        </GridItem>
        <GridItem
          colSpan={5}
          display={{ base: 'none', xl: 'block' }}
          alignSelf='start'
          mt='60px'>
          <Box>
            <Flex h={'100%'} direction={'column'}>
              <Text
                alignSelf='center'
                maxW='397px'
                as='span'
                color='purchr.blue.dark'
                fontWeight='400'
                fontSize='24px'
                mt='24px'>
                Alerting you to
                <Text
                  as='span'
                  color='purchr.brown.dark'
                  fontWeight='700'
                  fontSize='24px'
                  ml='6px'>
                  price drops
                </Text>{' '}
                on your favourite items, everywhere.
              </Text>
              <Box h={`calc(${window.innerHeight}px - 156px)`}>
                <Flex h='100%' alignItems='center' justifyContent='center'>
                  <Image src={HeroImage} zIndex='1' />
                </Flex>
              </Box>
            </Flex>
          </Box>
        </GridItem>
      </Grid>
    </Box>
  );
}
