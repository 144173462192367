import purchrAxios from './purchrAxios';

export default class UsersService {
  static async me(generateToken) {
    let url = '/users/me'
    if(generateToken) {
      url = `${url}?generateToken=${generateToken}`
    }

    const response = await purchrAxios.get(url);
    return response.data;
  }

  static async createUser(userInfo) {
    console.log('Registering user.');
    const response = await purchrAxios.post(
      '/users/create',
      { ...userInfo },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    return response.data;
  }

  
  static async updateUser(userInfo) {
    console.log('Updating user settings:', userInfo);
    const response = await purchrAxios.put(
      '/users/me',
      userInfo,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    return response.data;
  }

  static async unSubscribeUser(tokenInfo) {
    console.log('Updating user.');

    const response = await purchrAxios.put(
      '/users/unsubscribe',
      { ...tokenInfo },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    return response.data;
  }

  static async verifyEmail() {
    const response = await purchrAxios.post(
      `/users/verify`,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    return response.data;
  }

  static async sendResetPasswordEmail(email) {
    const response = await purchrAxios.post(
      '/users/forgot-password',
      { email },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    return response.data;
  }

  static validateUserInfo(userInfo, page = 'REGISTER') {
    if (!userInfo.email) {
      throw new Error(`Email is required`);
    }

    if (!userInfo.password) {
      throw new Error(
        page === 'REGISTER'
          ? `Password is required`
          : `Old password is required.`
      );
    }

    if (!userInfo.reTypePassword) {
      throw new Error(
        page === 'REGISTER'
          ? `Re-enter valid password`
          : `Re-enter valid new password`
      );
    }

    if (userInfo.password !== userInfo.reTypePassword) {
      throw new Error(
        page === 'REGISTER'
          ? `Passwords doesn't match`
          : `New passwords doesn't match`
      );
    }
  }
}
