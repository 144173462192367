import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const solid = defineStyle({
  fontSize: '14px',
  fontWeight: '600',
  _disabled: { bg: 'purchr.brown.dark !important', cursor: 'pointer !important' },
  _hover: {
    bg: 'purchr.orange.light',
  },
  bg: 'purchr.brown.dark',
  color: 'white',
  w: '100%',
  h: 'auto',
  p: '12px',
  borderRadius: '8px',
});

const outline = defineStyle({
  fontSize: '14px',  
  fontWeight: '600px',
  boxSizing: 'border-box',
  h: 'auto',
  p: '8px 14px',
  color: 'black',
  _hover: { bg: 'purchr.orange.light', color: 'white' },
  _disabled: { bg: 'transparent', color: 'black !important' },
  cursor: 'pointer !important',
  borderRadius: '8px',
  borderColor: 'purchr.gray.300',
});

const icon = defineStyle({});

export const buttonTheme = defineStyleConfig({
  variants: {
    solid,
    icon,
    outline,
  },
});

export default buttonTheme;
