import React, { useContext, useState } from 'react';
import { Box, Button, Flex, Text, useToast } from '@chakra-ui/react';
import SettingsRadio from '../../../components/SettingsRadio';
import { LuBellRing } from 'react-icons/lu';
import { FaListUl } from 'react-icons/fa6';
import { BsBellSlash } from "react-icons/bs";
import { GoThumbsup, GoThumbsdown } from "react-icons/go";
import { AuthContext } from '../../../context/auth.context';
import { UsersService } from '../../../services';

const priceAlertOptions = [
  {
    type: 'WHEN_DROP_DETECTED',
    title: 'When drop detected',
    subTitle: 'Separate emails for every drop',
    icon: LuBellRing,
  },
  {
    type: 'DAILY',
    title: 'Daily summary',
    subTitle: 'Maximum 1 email per day',
    icon: FaListUl,
  },
  {
    type: 'STOP_ALL_PRICE_ALERTS',
    title: 'Stop all alerts',
    subTitle: 'Turn off all price alert emails',
    icon: BsBellSlash,
  },
];

const temporaryAlertOptions = [
  {
    type: 'WHEN_DROP_DETECTED',
    title: 'Recommend me',
    subTitle: 'If we find your item at a better price at a different store',
    icon: GoThumbsup,
  },
  {
    type: 'STOP_ALL_PRICE_ALERTS',
    title: `Don't recommend`,
    subTitle: 'If we find your item at a better price at a different store',
    icon: GoThumbsdown,
  },
];

const Notification = () => {
  const toast = useToast();
  const isMobileSize = window.innerWidth <= 425;

  const { currentUser, updateCurrentUser } = useContext(AuthContext);
  const [settings, setSettings] = useState({
    priceAlert: currentUser.settings?.priceAlert || 'WHEN_DROP_DETECTED',
    temporaryAlert: currentUser.settings?.temporaryAlert || 'WHEN_DROP_DETECTED'
  });
  const [updatingSettings, setUpdatingSettings] = useState(false);

  const handleSettingChange = (type, value) => {
    setSettings(prev => ({
      ...prev,
      [type]: value
    }));
  };

  const updateSetting = async () => {
    setUpdatingSettings(true);
    try {
      // Send complete settings object
      const updateData = {
        settings: {
          priceAlert: settings.priceAlert,
          temporaryAlert: settings.temporaryAlert
        }
      };

      const response = await UsersService.updateUser(updateData);
      
      updateCurrentUser({
        ...currentUser,
        settings: {
          ...currentUser.settings,
          ...response.settings
        }
      });

      toast({
        title: 'Settings updated successfully!',
        status: 'success',
        duration: 2000,
        isClosable: true,
        position: 'top-right'
      });
    } catch (error) {
      toast({
        title: 'Failed to update settings',
        description: error.response?.data?.message || 'Please try again later.',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right'
      });
    } finally {
      setUpdatingSettings(false);
    }
  };

  const resetForm = () => {
    setSettings({
      priceAlert: currentUser.settings?.priceAlert || 'WHEN_DROP_DETECTED',
      temporaryAlert: currentUser.settings?.temporaryAlert || 'WHEN_DROP_DETECTED'
    });
  };

  return (
    <Box display='flex' flexDirection='column'>
      <Box
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        padding={{ base: '0', sm: '0 0 18px 0' }}
        borderBlockEnd={{ base: '0', sm: '1px' }}
        borderBlockEndColor={{ base: '', sm: '#EAECF0' }}
        order={{ base: 2, sm: 1 }}>
        <Box display={{ base: 'none', sm: 'block' }}>
          <Text fontSize='16px' fontWeight='500' color='#101828'>
            Notification settings
          </Text>
          <Text fontSize='12px' fontWeight='400' color='purchr.gray.light'>
            Manage what and when you are alerted by our platform
          </Text>
        </Box>
        <Box display='flex' gap='10px' mt={{ base: '20px', sm: '0' }} mb={{ base: '24px', sm: '0' }}>
          <Button
            variant='outline'
            w='100%'
            minW='80px'
            color='#6C6C6C'
            onClick={resetForm}
            disabled={updatingSettings}>
            Reset
          </Button>
          <Button
            variant='solid'
            minW='80px'
            isLoading={updatingSettings}
            loadingText="Saving..."
            onClick={updateSetting}
            colorScheme='blue'>
            Save
          </Button>
        </Box>
      </Box>
      <Flex
        direction='column'
        gap={{ base: '11px', xl: '18px' }}
        mt={{ base: '24px', xl: '22px' }}
        order={{ base: 1, sm: 2 }}>
        <Box maxW={{ lg: '98%', xl: '70% ', '2xl': '62%' }}>
          <Text
            mb='18px'
            minW='135px'
            color='#6C6C6C'
            fontSize='12px'
            fontWeight='500'
            display='flex'
            justifyContent='start'>
            Price drop alerts
          </Text>
          <Box display='flex' justifyContent={isMobileSize ? 'center' : 'end'}>
            <SettingsRadio
              settings={priceAlertOptions}
              selectedType={settings.priceAlert}
              onValueChange={(value) => handleSettingChange('priceAlert', value)}
              name='price-alert-settings'
            />
          </Box>
        </Box>
        <Box maxW={{ lg: '70%', xl: '50% ', '2xl': '45%' }}>
          <Text
            mb='18px'
            minW='135px'
            color='#6C6C6C'
            fontSize='12px'
            fontWeight='500'
            display='flex'
            justifyContent='start'>
            Recommendations
          </Text>
          <Box
            flex='30%'
            display='flex'
            justifyContent={isMobileSize ? 'center' : 'end'}>
            <SettingsRadio
              settings={temporaryAlertOptions}
              selectedType={settings.temporaryAlert}
              onValueChange={(value) => handleSettingChange('temporaryAlert', value)}
              name='temporary-alert-settings'
            />
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};

export default Notification;