import React from "react";
import { AlertHistoryComponent } from '../../components';

const AlertHistory = () => {
  return (
    <div>
      <AlertHistoryComponent />
    </div>
  );
};

export default AlertHistory;