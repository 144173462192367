import axios from 'axios';

import 'firebase/auth';
import 'firebase/firestore';

import { auth } from '../firebase';

import Utils from './utils';
import config from '../config/config';

let apiUrl = 'http://localhost:5001/purchr-ce58e/us-central1/purchr';
if (config.ENV !== 'development') {
  apiUrl = 'https://us-central1-purchr-ce58e.cloudfunctions.net/purchr';
}

const devAxios = axios.create({
  baseURL: apiUrl,
});

devAxios.CancelToken = axios.CancelToken;
devAxios.isCancel = axios.isCancel;

devAxios.interceptors.request.use(async (config) => {
  if (auth.currentUser) {
    const token = await auth.currentUser.getIdToken();
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

devAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (devAxios.isCancel(error)) {
      return {
        data: {
          type: 'canceled',
          error: error.message || 'Aborted',
        },
      };
    }

    console.log(error);
    let response = {};
    if (error.response) {
      response = error.response;
    } else if (error.request) {
      response = {
        data: {
          type: 'error',
          message: error.responseText || error.message,
        },
      };
    } else {
      response = {
        data: {
          type: 'error',
          message: error.message,
        },
      };
    }

    return await Utils.handleResponseError(response);
  }
);

export default devAxios;
