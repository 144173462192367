import React from 'react';
import { Box, Flex, Text, Button, useToast } from '@chakra-ui/react';
import { PurchrSelect } from '../../../components';
import { Textarea } from '@chakra-ui/react';
import { useState } from 'react';
import { FeedbacksService } from '../../../services';

let feedbackOptions = [
  { type: 'Bug Report' },
  { type: `Can't Save Item To Watch List` },
  { type: 'General Feedback' },
  { type: 'Feature Request' },
  { type: 'Something Else ' },
];

feedbackOptions = feedbackOptions.map((feedbackOption) => ({
  value: feedbackOption.type,
  label: feedbackOption.type,
}));

const Feedback = () => {
  const toast = useToast()

  const [feedback, setFeedback] = useState({
    type: 'Bug Report',
    feedback: ''
  });

  const [submitting, setSubmitting] = useState(false)

  const submitFeedback = async () => {
    try {
      setSubmitting(true)
      await FeedbacksService.create({ ...feedback })
      setFeedback({
        type: 'Bug Report',
        feedback: ''
      })

      toast({
        title: 'Thank you for your feedback.',
        status: 'success',
        duration: '2000',
      });

      setSubmitting(false)
    } catch (error) {
      toast({
        title: 'Error while submitting feedback. Please try again.',
        status: 'error',
        duration: '2000',
      });

      setSubmitting(false)
    }
  }

  return (
    <Box>
      <Box
        padding={{ base: '0', sm: '0 0 18px 0' }}
        borderBlockEnd={{ base: '0', sm: '1px' }}
        borderBlockEndColor={{ base: '', sm: '#EAECF0' }}
      >
        <Box display={{ base: 'none', sm: 'block' }}>
          <Text fontSize='16px' fontWeight='500' color='#101828'>
            Your feedback
          </Text>
          <Text fontSize='12px' fontWeight='400' color='purchr.gray.light'>
            We’d love to hear from you
          </Text>
        </Box>
      </Box>

      <Flex
        direction='column'
        gap={{ base: '11px', sm: '18px' }}
      >
        <Flex
          gap={{ base: '1px', sm: '60px', xl: '92px', '2xl': '200px' }}
          maxW={{ base: '100%', xl: '80%', '2xl': '60%' }}
          direction={{ base: 'column', sm: 'row', xl: 'row' }}
          mt={{ base: '20px', sm: '42px' }}>
          <Text minW='105px' color='#6C6C6C' fontSize='12px' fontWeight='500'>
            Type of feedback
          </Text>
          <Flex gap='16px' flex='1'>
            <Box flex='1'>
              <PurchrSelect
                data={feedbackOptions}
                value={feedback.type}
                onChange={(value) => {
                  setFeedback({ ...feedback, type: value.value });
                }}
                externalStyle={{
                  valueContainer: (provided) => ({
                    ...provided,
                    display: 'flex',
                  }),
                }}
              />
            </Box>
          </Flex>
        </Flex>
        <Box display={{ base: 'none', sm: 'block' }}>
          <hr style={{ borderColor: '#EAECF0' }} opacity='50%' />
        </Box>
        <Flex
          gap={{ base: '1px', sm: '40px', xl: '72px', '2xl': '180px' }}
          maxW={{ base: '100%', xl: '80%', '2xl': '60%' }}
          direction={{ base: 'column', sm: 'row', xl: 'row' }}>
          <Text minW='105px' color='#6C6C6C' fontSize='12px' fontWeight='500'>
            Give us your feedback
          </Text>
          <Flex flex={1}>
            <Textarea
              p='10px 12px'
              borderRadius='8px'
              placeholder='Feedback here'
              textColor='#667085'
              fontSize='16px'
              fontWeight='400px'
              borderColor='#D0D5DD'
              rows='6'
              size='sm'
              value={feedback.feedback}
              onChange={(e) =>
                setFeedback({ ...feedback, feedback: e.target.value })}
            />
          </Flex>
        </Flex>
        <Box display={{ base: 'none', sm: 'block' }}>
          <hr style={{ borderColor: '#EAECF0' }} opacity='50%' />
        </Box>
        <Box display='flex' justifyContent='end' mt={{ base: '20px', sm: '15px' }} maxW={{ base: '100%', xl: '80%', '2xl': '60%' }}>
          <Button
            variant='solid'
            maxW={{ base: '100px', sm: '170px' }}
            isLoading={submitting}
            onClick={submitFeedback}
          >
            {`Submit >`}
          </Button>
        </Box>
      </Flex>
    </Box>
  )
}

export default Feedback