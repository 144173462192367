import React, { useContext, useState } from 'react';
import { Box, Button, Flex, Text, useToast, Icon } from '@chakra-ui/react';
import { PurchrInput, PurchrSelect } from '../../../components';
import { getData } from 'country-list';
import currenyData from 'currency-codes/data';
import { AuthContext } from '../../../context/auth.context';
import { getAllTimezones } from 'countries-and-timezones';
import { sortBy } from 'lodash';
import { UsersService } from '../../../services';

import { GrCurrency } from 'react-icons/gr';
import { IoTimeOutline } from 'react-icons/io5';
import { MdOutlineEmail } from 'react-icons/md';
import { IoIosLogOut } from 'react-icons/io';

const MyDetails = () => {
  const toast = useToast();

  const { currentUser, updateCurrentUser ,logoutUser } = useContext(AuthContext);

  const [userInfo, setUserInfo] = useState({
    firstName: currentUser.firstName || '',
    lastName: currentUser.lastName || '',
    email: currentUser.email || '',
    country: currentUser.country || 'Australia',
    currency: currentUser.currency || 'USD - US Dollar',
    timezone: currentUser.timezone || 'GMT+10:00 - Australia/Sydney',
  });

  const [updatingUser, setUpdatingUser] = useState(false);
  const [updatingSettings, setUpdatingSettings] = useState(false);

  let timezones = Object.values(getAllTimezones());
  timezones = sortBy(timezones, (timezones) => {
    const utcOffsetNum = parseFloat(
      timezones.utcOffsetStr.replace(/[^\d.-]/g, '')
    );
    return utcOffsetNum;
  });

  const updateUser = async () => {
    const result = validateUser();
    if (result?.error) {
      return toast({
        title: result.error,
        status: 'error',
        duration: '2000',
      });
    }

    setUpdatingUser(true);
    await UsersService.updateUser({
      ...userInfo,
    });

    setUpdatingUser(false);

    updateCurrentUser({
      ...currentUser,
      ...userInfo,
    });

    toast({
      title: 'User updated successfully.',
      status: 'success',
      duration: '2000',
    });
  };

  const validateUser = () => {
    if (!userInfo.firstName) {
      return { error: 'First name is required' };
    }

    if (!userInfo.lastName) {
      return { error: 'Last name is required' };
    }
  };

  const resetForm = () => {
    setUpdatingSettings(true);
    setUserInfo({
      ...currentUser,
    });

    setUpdatingSettings(false);
  };
  
  const countryDatas = getData().map((countryData) => ({
    value: countryData.name,
    label: countryData.name,
    icon: countryData.code.toLowerCase(),
  }));

  const currenyDatas = currenyData.map((currency) => ({
    value: currency.code + ' - ' + currency.currency,
    label: currency.code + ' - ' + currency.currency,
  }));

  const timeZoneDatas = timezones.map((timezone) => ({
    value: `GMT${timezone.utcOffsetStr} - ${timezone.name}`,
    label: `GMT${timezone.utcOffsetStr} - ${timezone.name}`,
  }));

  return (
    <Box>
      <Flex direction='column'>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='space-between'
          padding={{ base: '0', sm: '0 0 18px 0' }}
          borderBlockEnd={{ base: '0', sm: '1px' }}
          borderBlockEndColor={{ base: '', sm: '#EAECF0' }}
          order={{ base: 2, sm: 1 }}>
          <Box display={{ base: 'none', sm: 'block' }}>
            <Text fontSize='16px' fontWeight='500' color='#101828'>
              Personal info
            </Text>
            <Text fontSize='12px' fontWeight='400' color='purchr.gray.light'>
              Update your photo and personal details here.
            </Text>
          </Box>
          <Box display='flex' gap='10px' mt={{ base: '20px', sm: '0' }} mb={{ base: '24px', sm: '0' }}>
            <Button
              variant='outline'
              w='100%'
              minW='80px'
              color='#6C6C6C'
              isLoading={updatingSettings}
              onClick={resetForm}>
              Reset
            </Button>
            <Button
              variant='solid'
              minW='80px'
              isLoading={updatingUser}
              onClick={updateUser}>
              Save
            </Button>
          </Box>
        </Box>
        <Flex
          order={{ base: 1, sm: 2 }}
          direction='column'
          gap={{ base: '11px', sm: '18px' }}
          mt={{ base: '24px', sm: '20px' }}>
          <Flex
            gap={{ base: '1px', sm: '40px', xl: '112px', '2xl': '200px' }}
            maxW={{ base: '100%', xl: '80%', '2xl': '60%' }}
            direction={{ base: 'column', sm: 'row', xl: 'row' }}>
            <Text minW='105px' color='#6C6C6C' fontSize='12px' fontWeight='500'>
              Name
            </Text>
            <Flex gap='16px' flex={1}>
              <Box flex={1}>
                <PurchrInput
                  lableProps={{
                    value: '',
                  }}
                  inputProps={{
                    h: '40px',
                    mt: '0',
                    type: 'text',
                    placeholder: 'Firstname',
                  }}
                  value={userInfo.firstName}
                  onChange={(e) => {
                    setUserInfo({ ...userInfo, firstName: e.target.value });
                  }}
                />
              </Box>
              <Box flex={1}>
                <PurchrInput
                  lableProps={{
                    value: '',
                  }}
                  inputProps={{
                    h: '40px',
                    mt: '0',
                    type: 'text',
                    placeholder: 'Lastname',
                  }}
                  value={userInfo.lastName}
                  onChange={(e) => {
                    setUserInfo({ ...userInfo, lastName: e.target.value });
                  }}
                />
              </Box>
            </Flex>
          </Flex>
          <Box display={{ base: 'none', sm: 'block' }}>
            <hr style={{borderColor: '#EAECF0'}} opacity='50%' />
          </Box>
          <Flex
            gap={{ base: '1px', sm: '40px', xl: '112px', '2xl': '200px' }}
            maxW={{ base: '100%', xl: '80%', '2xl': '60%' }}
            direction={{ base: 'column', sm: 'row', xl: 'row' }}>
            <Text minW='105px' color='#6C6C6C' fontSize='12px' fontWeight='500'>
              Email address
            </Text>
            <Flex gap='16px' flex={1}>
              <Box flex={1}>
                <PurchrInput
                  lableProps={{
                    value: '',
                  }}
                  inputProps={{
                    pl:'40px',
                    h: '40px',
                    mt: '0',
                    type: 'text',
                    isDisabled: true,
                  }}
                  icon={<MdOutlineEmail  color='#A7A7A7'/>}
                  value={userInfo.email}
                />
              </Box>
            </Flex>
          </Flex>
          <Box display={{ base: 'none', sm: 'block' }}>
            <hr style={{borderColor: '#EAECF0'}} opacity='50%' />
          </Box>
          <Flex
            gap={{ base: '1px', sm: '40px', xl: '112px', '2xl': '200px' }}
            maxW={{ base: '100%', xl: '80%', '2xl': '60%' }}
            direction={{ base: 'column', sm: 'row', xl: 'row' }}>
            <Text minW='105px' color='#6C6C6C' fontSize='12px' fontWeight='500'>
              Country
            </Text>
            <Flex gap='16px' flex={1}>
              <Box flex={1}>
                <PurchrSelect
                  data={countryDatas}
                  value={userInfo.country}
                  onChange={(value) =>
                    setUserInfo({ ...userInfo, country: value.value })
                  }
                  externalStyle={{
                    valueContainer: (provided) => ({
                      ...provided,
                      display: 'flex',
                    }),
                  }}
                />
              </Box>
            </Flex>
          </Flex>
          <Box display={{ base: 'none', sm: 'block' }}>
            <hr style={{borderColor: '#EAECF0'}} opacity='50%' />
          </Box>
          <Flex
            gap={{ base: '1px', sm: '40px', xl: '112px', '2xl': '200px' }}
            maxW={{ base: '100%', xl: '80%', '2xl': '60%' }}
            direction={{ base: 'column', sm: 'row', xl: 'row' }}>
            <Text minW='105px' color='#6C6C6C' fontSize='12px' fontWeight='500'>
              Currency
            </Text>
            <Flex gap='16px' flex={1}>
              <Box flex={1}>
                <PurchrSelect
                  data={currenyDatas}
                  value={userInfo.currency}
                  onChange={(value) =>
                    setUserInfo({ ...userInfo, currency: value.value })
                  }
                  leftIcon={GrCurrency}
                  externalStyle={{
                    valueContainer: (provided) => ({
                      ...provided,
                      display: 'flex',
                    }),
                    input: (provided) => ({
                      ...provided,
                      marginLeft: '8px',
                    }),
                  }}
                />
              </Box>
            </Flex>
          </Flex>
          <Box display={{ base: 'none', sm: 'block' }}>
            <hr style={{borderColor: '#EAECF0'}} opacity='50%' />
          </Box>
          <Flex
            gap={{ base: '1px', sm: '40px', xl: '112px', '2xl': '200px' }}
            maxW={{ base: '100%', xl: '80%', '2xl': '60%' }}
            direction={{ base: 'column', sm: 'row', xl: 'row' }}>
            <Text minW='105px' color='#6C6C6C' fontSize='12px' fontWeight='500'>
              Timezone
            </Text>
            <Flex gap='16px' flex={1}>
              <Box flex={1}>
                <PurchrSelect
                  data={timeZoneDatas}
                  value={userInfo.timezone}
                  onChange={(value) =>
                    setUserInfo({ ...userInfo, timezone: value.value })
                  }
                  leftIcon={IoTimeOutline}
                  externalStyle={{
                    valueContainer: (provided) => ({
                      ...provided,
                      display: 'flex',
                    }),
                    input: (provided) => ({
                      ...provided,
                      marginLeft: '8px',
                    }),
                  }}
                />
              </Box>
            </Flex>
            <Button
              lineHeight='normal'
              rightIcon={<Icon as={IoIosLogOut} h='18px' />}
              variant='outline'
              w='112px'
              fontWeight='500'
              mt='16px'
              padding='12px 20px'
              size='sm'
              gap='4px'
              color='purchr.blue.dark'
              display={{ base: 'flex', sm: 'none' }}
              onClick={logoutUser}
            >
              Logout
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

export default MyDetails;
