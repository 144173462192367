import purchrAxios from './purchrAxios';

export default class FeedbacksService {
  static async create(feedbackInfo) {
    console.log('Registering feedback');
    const response = await purchrAxios.post(
      '/feedbacks',
      { ...feedbackInfo },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    return response.data;
  }
}
