import React, { useContext, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router";
import { AuthContext } from "../../../context/auth.context";
import { BiSolidBinoculars } from "react-icons/bi";
import { MdOpenInNew } from "react-icons/md";

import FeedbackIcon from '../../../assets/feedback-icon.svg'
import { TbBellRinging } from "react-icons/tb";
import { IoIosLogOut } from "react-icons/io";

import ChromeImage from "../../../assets/chrome.svg";
import userImage from "../../../assets/user-image.jpg";
import ArrowRight from '../../../assets/arrow_right.png';

import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Icon,
  Image,
  Link,
  Progress,
  Tab,
  TabList,
  Tabs,
  Text,
  Tooltip,
} from '@chakra-ui/react';

import infoIcon from '../../../assets/info.svg';
import { ImInfinite } from 'react-icons/im';

import LogoImage from "../../../assets/logo_tag.svg";
import { NavLink } from "react-router-dom";
import { GlobalContext } from '../../../context/global.context';
import { AdminConfigService } from '../../../services';

export default function DesktopLayout() {
  const { currentUser, logoutUser, currentUserMatched } =
    useContext(AuthContext);
  const { itemsRemaining, allSavedItems } = useContext(GlobalContext);
  const location = useLocation();
  const [limitLength, setLimitLength] = useState(0);

  useEffect(() => {
    const handleLimit = async () => {
      const response = await AdminConfigService.all();
      setLimitLength(
        currentUserMatched ? allSavedItems.length : response[0].savedItemLimit
      );
    };
    handleLimit();
  }, [allSavedItems, currentUserMatched]);

  let defaultTabIndex = 3;
  if (location.pathname.includes("alerts")) {
    defaultTabIndex = 1;
  } else if (location.pathname === "/") {
    defaultTabIndex = 0;
  }

  const [tabIndex, setTabIndex] = useState(defaultTabIndex);

  const history = useNavigate();
  useEffect(() => {
    if (location.pathname.includes("alerts")) {
      return setTabIndex(1);
    }

    if (location.pathname === "/") {
      return setTabIndex(0);
    }

    return setTabIndex(3); // set to 3 to unselect all
  }, [location]);

  const handleTabsChange = (index) => {
    setTabIndex(index);
  };

  useEffect(() => {
    if (currentUser && !currentUser.onboardingCompleted) {
      return history("/register");
    }
  }, [location, currentUser, history]);
  return (
    <Grid h={`${window.innerHeight}px`} templateColumns="repeat(10, 1fr)">
      <GridItem
        colSpan={{ base: 3, lg: 1 }}
        minW={{ base: "auto", lg: "250px" }}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        m={{ base: "28px", lg: "28px 44px" }}
        mr="0px !important"
      >
        <Box pt="8px">
          <NavLink to="/">
            <Image src={LogoImage} h="72px" />
          </NavLink>

          <Tabs
            variant="unstyled"
            mt="52px"
            index={tabIndex}
            onChange={handleTabsChange}
          >
            <TabList flexDir="column" gap="12px">
              <NavLink to="/">
                <Tab
                  w="100%"
                  color="purchr.blue.dark"
                  _selected={{ color: "white", bg: "purchr.brown.dark" }}
                  p="10px 20px 10px 28px"
                  borderRadius="8px"
                  gap="20px"
                  justifyContent="start"
                >
                  <Icon as={BiSolidBinoculars} h="20px" w="20px" />
                  <Text fontSize="12px" fontWeight="700">
                    Watch list
                  </Text>
                </Tab>
              </NavLink>
              <NavLink to="/alerts">
                <Tab
                  w="100%"
                  color="purchr.blue.dark"
                  _selected={{ color: "white", bg: "purchr.brown.dark" }}
                  p="10px 20px 10px 28px"
                  borderRadius="8px"
                  gap="20px"
                  justifyContent="start"
                >
                  <Icon as={TbBellRinging} h="20px" w="20px" />
                  <Text fontSize="12px" fontWeight="700">
                    Alert History
                  </Text>
                </Tab>
              </NavLink>
            </TabList>
          </Tabs>
        </Box>

        <Box fontSize='12px' fontWeight='700'>
          <Box m='12px 0px 20px 0px'>
            <Tooltip
              label='The limit of items you will receive price alerts for'
              hasArrow
              w='220px'
              placement='right'
            >
              <Image src={infoIcon} w='12px' h='12px' mb='4px' />
            </Tooltip>

            <Flex
              color='purchr.gray.light'
              justifyContent='space-between'
              mb='8px'
            >
              <Text>Items Remaining</Text>
              <Flex alignItems='center'>
                <Text>{itemsRemaining}/</Text>
                {currentUserMatched ? <ImInfinite /> : limitLength}
              </Flex>
            </Flex>

            <Progress
              value={(itemsRemaining * 100) / limitLength}
              size='xs'
              colorScheme={(itemsRemaining * 100) / limitLength >= 75 ? 'red' : 'green'}
              borderRadius='99px'
            />
          </Box>

          <Box borderTop='1px solid #E6E6E6' pt='16px'>
            <Text color='purchr.blue.dark'>Explore</Text>
            {/* <NavLink to='/my-account#billing'>
              <Flex pt='12px'>
                <Image src={creditCard} h='16px' w='16px' mr='12px'></Image>
                <Text color='purchr.gray.light'>Billing</Text>
              </Flex>
            </NavLink> */}
            <NavLink to="/my-account#feedback">
              <Flex pt="12px" color="purchr.gray.light">
                <Image src={FeedbackIcon} h="21px" w="21px" mr="12px" />
                <Text>Feedback</Text>
              </Flex>
            </NavLink>
            <NavLink to="https://purchr.com/launch-day/">
              <Flex p="12px 0 56px 0" color="purchr.gray.light">
                <Image src={ArrowRight} h="16px" w="16px" mr="12px"></Image>
                <Text>How to get started</Text>
              </Flex>
            </NavLink>
          </Box>

          <Flex flexDir="column" gap="8px">
            <Link
              href="https://chromewebstore.google.com/detail/blljpkobmadahidjcappomekbkpnnbaf"
              isExternal
              textDecoration="none"
              _hover={{ textDecoration: "none" }}
            >
              <Box
                position="relative"
                borderRadius="8px"
                border="1px solid #4F0A43"
                p="16px 8px 16px 16px"
                cursor="pointer"
                color="purchr.blue.dark"
                role="group"
                _hover={{ bg: "purchr.brown.dark", color: "white !important" }}
              >
                <Image
                  src={ChromeImage}
                  h="36px"
                  w="36px"
                  borderRadius="50%"
                />
                <Icon
                  position="absolute"
                  top="12px"
                  right="12px"
                  as={MdOpenInNew}
                  h="16px"
                  w="16px"
                />
                <Text fontSize="16px" fontWeight="700" p="8px 0 4px 0">
                  Purchr Extension
                </Text>
                <Text
                  color="purchr.gray.light"
                  _groupHover={{ color: "white" }}
                  fontSize="8px"
                  fontWeight="600"
                >
                  {`Add Extension >`}
                </Text>
              </Box>
            </Link>

            <NavLink to="/my-account">
              <Flex
                borderRadius="8px"
                border="1px solid #4F0A43"
                p="16px 0 24px 12px"
                role="group"
                cursor="pointer"
                bg={
                  location.pathname.includes("my-account")
                    ? "purchr.brown.dark"
                    : ""
                }
                color={
                  location.pathname.includes("/my-account")
                    ? "white !important"
                    : "purchr.blue.dark"
                }
                _hover={{ bg: "purchr.brown.dark", color: "white !important" }}
              >
                <Image
                  src={userImage}
                  height="42px"
                  width="44px"
                  me="8px"
                  border="1px solid #DADADA"
                  borderRadius="100px"
                ></Image>
                <Box>
                  <Text fontSize="16px" fontWeight="700">
                    {currentUser.firstName} {currentUser.lastName}
                  </Text>
                  <Text fontSize="10px" fontWeight="600" p="4px 0 12px 0">
                    {currentUser.email}
                  </Text>
                  <Text fontSize="8px" fontWeight="600">
                    {`Account Settings >`}
                  </Text>
                </Box>
              </Flex>
            </NavLink>
          </Flex>

          <Flex flexDir="column" alignItems="center">
            <Button
              rightIcon={<Icon as={IoIosLogOut} h="12px" w="10px"></Icon>}
              variant="outline"
              fontSize="12px"
              fontWeight="500"
              mt="28px"
              onClick={logoutUser}
            >
              Logout
            </Button>
            <Link
              href='https://purchr.com/wp-content/uploads/2024/04/Purchr-TERMS-OF-SERVICE-SaaS.pdf'
              isExternal
              color="purchr.gray.light"
              fontSize="8px"
              fontWeight="400"
              mt="12px"
            >
              {`Terms & Conditions >`}
            </Link>
            <Link
              href='https://purchr.com/wp-content/uploads/2024/04/Purchr-Privacy-Policy.pdf'
              isExternal
              color="purchr.gray.light"
              fontSize="8px"
              fontWeight="400"
              mt="4px"
            >
              {`Privacy Policy >`}
            </Link>
          </Flex>
        </Box>
      </GridItem>
      <GridItem colSpan={{ base: 7, lg: 9 }}>
        <Box
          h={{
            base: `calc(${window.innerHeight}px - 60px)`,
            sm: `calc(${window.innerHeight}px - 48px)`,
          }}
          m="24px"
          mr="0"
          bg="purchr.gray.bg"
          borderRadius="45px 0 0 45px"
          pt="56px"
          px="45px"
        >
          <Outlet />
        </Box>
      </GridItem>
    </Grid>
  );
}
