import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  GridItem,
  Flex,
  Image,
  Card,
  Text,
  Spinner,
  useToast,
} from "@chakra-ui/react";

import { BackGroundUI } from "../../components";

import HeroImage from "../../assets/Hero.webp";
import LogoImage from "../../assets/logo.svg";

import { useNavigate, useSearchParams } from "react-router-dom";
import { UsersService } from "../../services";

export default function UnSubscribe() {
  const navigate = useNavigate();
  const toast = useToast();

  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const [unsubscribeUser, setUnsubscribeUser] = useState(false);

  useEffect(() => {
    const newSettings = async () => {
      if (token) {
        try {
          await UsersService.unSubscribeUser({
            token,
          });

          setUnsubscribeUser(true);
          navigate("/unsubscribe");
        } catch (error) {
          navigate("/unsubscribe");
          toast({
            title: 'Error while unsubscribing user. Try again.',
            status: 'error',
            duration: '2000',
          });
        }
      }
    };
    newSettings();
  }, [token]);

  return (
    <Box
      bg="purchr.bgHero"
      h={`${window.innerHeight}px`}
      p={{ base: "0", sm: "0px", xl: "0 40px 0 40px" }}
    >
      <BackGroundUI />
      <Grid
        h="100%"
        templateColumns="repeat(10, 1fr)"
        gap="16px"
        alignItems="center"
      >
        <GridItem colSpan={{ base: 10, xl: 5, "2xl": 4 }}>
          <Box>
            <Flex h="100%" direction="column" alignItems="center">
              <Image src={LogoImage} height="100px" mb="24px" zIndex={1} />
              <Card
                p={{ base: "48px", sm: "90px 75px" }}
                borderRadius="42px"
                w={{ base: "auto", sm: "544px" }}
              >
                {!unsubscribeUser ? (
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    height="100%"
                  >
                    <Spinner size="md" />
                  </Flex>
                ) : (
                  <Text>
                    <Text
                      fontSize="24px"
                      fontWeight="bold"
                      color="purchr.textPrimary"
                    >
                      You have unsubscribed successfully
                    </Text>
                  </Text>
                )}
              </Card>
            </Flex>
          </Box>
        </GridItem>
        <GridItem
          colSpan={{ base: 0, xl: 5, "2xl": 6 }}
          display={{ base: "none", xl: "block" }}
          alignSelf="start"
          mt="60px"
        >
          <Box>
            <Flex h={"100%"} direction={"column"}>
              <Text
                mt="24px"
                maxW="397px"
                as="span"
                alignSelf="center"
                color="purchr.blue.dark"
                fontWeight="400"
                fontSize="24px"
              >
                Alerting you to
                <Text
                  as="span"
                  color="purchr.brown.dark"
                  fontWeight="700"
                  fontSize="24px"
                  ml="6px"
                >
                  price drops
                </Text>{" "}
                on your favourite items, everywhere.
              </Text>
              <Box h={`calc(${window.innerHeight}px - 156px)`}>
                <Flex h="100%" alignItems="center" justifyContent="center">
                  <Image src={HeroImage} zIndex="1" />
                </Flex>
              </Box>
            </Flex>
          </Box>
        </GridItem>
      </Grid>
    </Box>
  );
}
