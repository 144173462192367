import React, { useRef, useState, useEffect } from 'react';
import {
  AlertHistoryDropDown,
  AlertHistoryItem,
  ContentHeader,
  ItemNotFound,
  SearchItems,
} from '..';
import { Box, Flex, Spinner } from '@chakra-ui/react';
import { useMediaQuery } from '@chakra-ui/react';
import { useAuth } from '../../context/auth.context';
import AlertsFiredService from '../../services/alertsFired.service';
import InfiniteScroll from 'react-infinite-scroller';

const AlertHistoryComponent = () => {
  const { currentUser, authPending } = useAuth();
  const [fetchingAlertItems, setFetchingAlertItems] = useState(false);
  const [isMobileSize] = useMediaQuery('(max-width: 425px)');
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [showEmpty, setShowEmpty] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageAlertItems, setCurrentPageAlertItems] = useState([]);
  const [allAlertItems, setAllAlertItems] = useState([]);
  const scrollContainerRef = useRef(null);
  const [selectedItem, setSelectedItem] = useState(null);

  // Calculate counters based on productId and scrapedAt order
  const calculateAlertCounters = (items) => {
    const groupedByProduct = {};
    items.forEach((item) => {
      if (!item.productId) return;
      if (!groupedByProduct[item.productId]) {
        groupedByProduct[item.productId] = [];
      }
      groupedByProduct[item.productId].push(item);
    });

    return items.map((item) => {
      if (!item.productId) return { ...item, counter: 0 };
      const group = groupedByProduct[item.productId];
      const sortedGroup = group.sort((a, b) => new Date(a.scrapedAt) - new Date(b.scrapedAt));
      const counter = sortedGroup.findIndex((i) => i === item) + 1;
      return { ...item, counter };
    });
  };

  const sortItemsByDate = (items) => {
    return [...items].sort((a, b) => new Date(b.scrapedAt) - new Date(a.scrapedAt));
  };

  // Load more alert items for infinite scroll
  const loadMoreAlertItems = async () => {
    if (fetchingAlertItems || !hasMoreItems || !currentUser?._id) return;

    setFetchingAlertItems(true);
    try {
      const params = {
        limit: 10,
        pageNo: currentPage,
        q: searchQuery,
      };

      const alertItemsInfo = await AlertsFiredService.getAlertsByUserId(currentUser._id, params);
      setHasMoreItems(alertItemsInfo.hasMoreItems || false);
      const alertItems = alertItemsInfo.records || alertItemsInfo || [];

      setAllAlertItems((prevItems) => {
        const uniqueItems = alertItems.filter(
          (newItem) => !prevItems.some((prevItem) => prevItem._id === newItem._id)
        );
        const updatedItems = [...prevItems, ...uniqueItems];
        return calculateAlertCounters(updatedItems);
      });
    } catch (error) {
      console.error('Error loading alerts:', error.response ? error.response.data : error.message);
      setShowEmpty(true);
    } finally {
      setFetchingAlertItems(false);
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  // Handle selecting an item from the dropdown
  const handleSelectItem = (alertItem) => {
    setSelectedItem(alertItem);
    setCurrentPage(1);
  };

  // Handle clearing the selected item filter
  const handleClearSelection = () => {
    setSelectedItem(null);
    setCurrentPage(1);
    setHasMoreItems(true);
  };

  // Handle search query changes
  const handleSearch = (searchQuery) => {
    setSearchQuery(searchQuery);
    setCurrentPage(1);
  };

  // Apply filters whenever allAlertItems, selectedItem, or searchQuery changes
  useEffect(() => {
    let filteredItems = [...allAlertItems];

    if (selectedItem?.productId) {
      filteredItems = filteredItems.filter((item) => item.productId === selectedItem.productId);
    }

    if (searchQuery) {
      filteredItems = filteredItems.filter((item) =>
        item.name?.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    setCurrentPageAlertItems(sortItemsByDate(filteredItems));

    if (filteredItems.length === 0) {
      setShowEmpty(true);
    } else {
      setShowEmpty(false);
    }
  }, [allAlertItems, selectedItem, searchQuery]);

  // Read saved filter from localStorage on component mount
  useEffect(() => {
    const savedAlertSearch = localStorage.getItem('alertSearch');
    if (savedAlertSearch) {
      try {
        const parsedSearch = JSON.parse(savedAlertSearch);
        setSelectedItem(parsedSearch);
        localStorage.removeItem('alertSearch');
      } catch (error) {
        console.error('Error parsing alertSearch:', error);
      }
    }
  }, []);

  // Trigger initial fetch when user is authenticated
  useEffect(() => {
    if (!authPending && currentUser?._id && currentPageAlertItems.length === 0 && !fetchingAlertItems) {
      loadMoreAlertItems();
    }
  }, [authPending, currentUser, currentPageAlertItems.length, fetchingAlertItems]);

  if (authPending) {
    return (
      <Flex justifyContent="center" alignItems="center" height="100vh">
        <Spinner size="xl" />
      </Flex>
    );
  }

  return (
    <Box display="flex" flexDirection="column" height="100vh">
      <ContentHeader
        title="Alert History"
        description="Review all your saved items, set price change alerts"
        headingStyle={{
          color: 'purchr.brown.dark',
          fontSize: { base: '32px', sm: '44px' },
          fontWeight: '700',
        }}
        descriptionStyle={{
          fontWeight: '400',
          fontSize: '14px',
          color: '#C0C0C0',
          display: { base: 'none', md: 'block' },
        }}
      />

      <SearchItems filterItemDatas={[]} handleSearch={handleSearch}>
        <AlertHistoryDropDown
          currentPageAlertItems={allAlertItems}
          selectedItem={selectedItem}
          handleSelectItem={handleSelectItem}
          handleClearSelection={handleClearSelection}
        />
      </SearchItems>

      <Box
        flex="1"
        overflowY="auto"
        style={{ scrollbarWidth: isMobileSize ? 'none' : 'thin' }}
        ref={scrollContainerRef}
        p={4} // Add padding for better spacing
      >
        {fetchingAlertItems && currentPageAlertItems.length === 0 ? (
          <Flex justifyContent="center" alignItems="center" height="100%">
            <Spinner size="md" />
          </Flex>
        ) : showEmpty || currentPageAlertItems.length === 0 ? (
          <Flex justifyContent="center" alignItems="center" height="100%">
            <ItemNotFound message="No items found." />
          </Flex>
        ) : (
          <InfiniteScroll
            loadMore={loadMoreAlertItems}
            getScrollParent={() => scrollContainerRef.current}
            threshold={0}
            hasMore={!fetchingAlertItems && hasMoreItems}
            useWindow={false}
          >
            {currentPageAlertItems.map((alertItem) =>
              alertItem ? (
                <AlertHistoryItem
                  key={alertItem._id}
                  alertItem={alertItem}
                  showOriginalPrice={true}
                  counter={alertItem.counter || 0}
                />
              ) : null
            )}
          </InfiniteScroll>
        )}
        {fetchingAlertItems && hasMoreItems ? (
          <Flex my="10px" alignItems="center" justifyContent="center">
            <Spinner size="md" />
          </Flex>
        ) : null}
      </Box>
    </Box>
  );
};

export default AlertHistoryComponent;
