import React, { useContext, useState } from 'react';
import {
  Box,
  Grid,
  GridItem,
  Flex,
  Image,
  Card,
  CardBody,
  Text,
  Button,
  Checkbox,
  Link,
} from '@chakra-ui/react';

import GoogleIcon from '../../assets/google.svg';
import HeroImage from '../../assets/Hero.webp';
import LogoImage from '../../assets/logo.svg';

import { NavLink, useNavigate } from 'react-router-dom';

import { BackGroundUI, ContentHeader, PurchrInput } from '../../components';
import { AuthContext } from '../../context/auth.context';

import { useToast } from '@chakra-ui/react';

export default function Register() {
  const toast = useToast();
  const history = useNavigate();

  const { authPending, authenticateUser, error, toggleError } =
    useContext(AuthContext);

  if (error) {
    toast({
      title: error,
      status: 'error',
      duration: '2000',
    });

    toggleError();
  }

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [reTypePassword, setReTypePassword] = useState('');
  const [provider, setProvider] = useState('');
  const [termsPrivacyAccepted, setTermsPrivacyAccepted] = useState(false);

  const handleEnter = (event, provider) => {
    if (event.key === 'Enter') {
      handleButtonClick(event, provider)
    }
  }

  const handleButtonClick = async (event, provider) => {
    event.preventDefault();

    setProvider(provider);

    const authenticated = await authenticateUser(provider, {
      email,
      password,
      reTypePassword,
      register: true,
      termsPrivacyAccepted
    });

    if (authenticated) {
      history('/');
    }
  };

  return (
    <Box
      bg='purchr.bgHero'
      h={`${window.innerHeight}px`}
      p={{ base: '0', sm: '0px', xl: '0 40px 0 40px' }}
      mb={{ base: '24px', sm: '0px' }}>
      <BackGroundUI />
      <Grid
        h='100%'
        templateColumns='repeat(10, 1fr)'
        gap='16px'
        alignItems='center'>
        <GridItem colSpan={{ base: 10, xl: 5, '2xl': 4 }}>
          <Box>
            <Flex h={'100%'} direction={'column'} alignItems={'center'}>
              <Image src={LogoImage} height='100px' mb='24px' zIndex='1' />
              <Card
                p={{ base: '48px', sm: '90px 75px' }}
                borderRadius='42px'
                w={{ base: 'auto', sm: '544px' }}>
                <CardBody p='0px'>
                  <ContentHeader
                    size='small'
                    title='Create account'
                    description='Start saving on your favourite items now!'
                    descriptionStyle={{ mt: '8px' }}
                  />
                  <PurchrInput
                    boxProps={{
                      mt: '24px',
                    }}
                    lableProps={{
                      value: 'Email',
                    }}
                    inputProps={{
                      type: 'email',
                      placeholder: 'Enter your email',
                    }}
                    value={email}
                    onChange={(event) => {
                      setEmail(event.target.value);
                    }}
                    onKeyDown={(event) => handleEnter(event, 'EMAIL')}
                  />
                  <PurchrInput
                    boxProps={{
                      mt: '16px',
                    }}
                    lableProps={{
                      value: 'Password',
                    }}
                    inputProps={{
                      type: 'password',
                      placeholder: 'Enter your password',
                    }}
                    value={password}
                    onChange={(event) => {
                      setPassword(event.target.value);
                    }}
                    onKeyDown={(event) => handleEnter(event, 'EMAIL')}
                  />
                  <PurchrInput
                    boxProps={{
                      mt: '16px',
                    }}
                    lableProps={{
                      value: 'Re-type Password',
                    }}
                    inputProps={{
                      type: 'password',
                      placeholder: 'Enter your password again',
                    }}
                    value={reTypePassword}
                    onChange={(event) => {
                      setReTypePassword(event.target.value);
                    }}
                    onKeyDown={(event) => handleEnter(event, 'EMAIL')}
                  />

                  <Checkbox style={{ marginTop: '10px' }} size='sm' colorScheme='orange' isChecked={termsPrivacyAccepted} onChange={() => {
                    setTermsPrivacyAccepted(!termsPrivacyAccepted);
                  }}>
                    <p style={{ fontSize: '12px' }}>I accept the <Link color='orange' href='https://purchr.com/wp-content/uploads/2024/04/Purchr-TERMS-OF-SERVICE-SaaS.pdf'>terms and conditions</Link> of use and <Link color='orange' href='https://purchr.com/wp-content/uploads/2024/04/Purchr-Privacy-Policy.pdf'>privacy policy</Link>.</p>
                  </Checkbox>

                  {termsPrivacyAccepted ? (<>
                    <Button
                    variant='solid'
                    mt='20px'
                    isLoading={provider === 'EMAIL' && authPending}
                    onClick={(event) => handleButtonClick(event, 'EMAIL')}>
                    Sign up
                    </Button>
                    <Button
                      variant='outline'
                      w='100%'
                      _hover={{ bg: 'transparent' }}
                      mt='12px'
                      isLoading={provider === 'GOOGLE' && authPending}
                      onClick={(event) => handleButtonClick(event, 'GOOGLE')}>
                      <Image src={GoogleIcon} alt='Google' w='16px' mr='12px' />
                      <Text color='purchr.gray.700'>Sign up with Google</Text>
                    </Button>
                  </>) : (<></>)}
                  
                  <Box
                    textAlign='center'
                    fontSize='xs'
                    fontWeight='500'
                    color='purchr.gray.500'
                    mt='24px'>
                    <Text as='span'>Already have an account?</Text>
                    <NavLink to='/login'>
                      <Text
                        as='span'
                        color='purchr.brown.dark'>{` Login here >`}</Text>
                    </NavLink>
                  </Box>
                </CardBody>
              </Card>
            </Flex>
          </Box>
        </GridItem>
        <GridItem
          colSpan={{ base: 0, xl: 5, '2xl': 6 }}
          display={{ base: 'none', xl: 'block' }}
          alignSelf='start'
          mt='60px'>
          <Box>
            <Flex h={'100%'} direction={'column'}>
              <Text
                alignSelf='center'
                maxW='397px'
                as='span'
                color='purchr.blue.dark'
                fontWeight='400'
                fontSize='24px'
                mt='24px'
              >
                Alerting you to
                <Text
                  as='span'
                  color='purchr.brown.dark'
                  fontWeight='700'
                  fontSize='24px'
                  ml='6px'>
                  price drops
                </Text>{' '}
                on your favourite items, everywhere.
              </Text>
              <Box h={`calc(${window.innerHeight}px - 156px)`}>
                <Flex h='100%' alignItems='center' justifyContent='center'>
                  <Image src={HeroImage} zIndex='1' />
                </Flex>
              </Box>
            </Flex>
          </Box>
        </GridItem>
      </Grid>
    </Box>
  );
}
