import React, { useContext } from 'react';
import {
  Box,
  Grid,
  GridItem,
  Flex,
  Image,
  Card,
  CardBody,
  Text,
  useToast,
  CardHeader,
} from '@chakra-ui/react';

import { BackGroundUI, ContentHeader } from '../../components';

import HeroImage from '../../assets/Hero.webp';
import LogoImage from '../../assets/logo.svg';

import { NavLink, useSearchParams } from 'react-router-dom';
import { AuthContext } from '../../context/auth.context';
import ChromeImage from '../../assets/chrome.svg';

export default function ExtentionSuccess() {
  const toast = useToast();

  const [searchParams] = useSearchParams();
  const token = searchParams?.get('token');
  console.log(token);

  const { error, toggleError } = useContext(AuthContext);

  if (error) {
    toast({
      title: error,
      status: 'error',
      duration: '2000',
    });

    toggleError();
  }

  return (
    <Box
      bg='purchr.bgHero'
      h={`${window.innerHeight}px`}
      p={{ base: '0', sm: '0px', xl: '0 40px 0 40px' }}
    >
      <BackGroundUI />
      <Grid
        h='100%'
        templateColumns='repeat(10, 1fr)'
        gap='16px'
        alignItems='center'
      >
        <GridItem colSpan={{ base: 10, xl: 5, '2xl': 4 }}>
          <Box>
            <Flex h='100%' direction='column' alignItems='center'>
              <Image src={LogoImage} height='100px' mb='24px' zIndex={1} />
              <Card
                p={{ base: '48px', sm: '90px 75px' }}
                borderRadius='42px'
                w={{ base: 'auto', sm: '544px' }}
                h='570px'
              >
                <CardHeader px='0px' pb='32px'>
                  <Image
                    src={ChromeImage}
                    h='64px'
                    w='64px'
                    borderRadius='50%'
                  ></Image>
                </CardHeader>

                <CardBody p='0px'>
                  <ContentHeader
                    size='small'
                    title='Success! Your chrome extension is now logged in'
                    headingStyle={{ fontSize: '28px' }}
                  />
                  <Flex
                    fontSize='xs'
                    fontWeight='400'
                    color='purchr.gray.500'
                    mt='16px'
                    flexDir='column'
                    gap='16px'
                  >
                    <Text as='span'>Reopen the extension now.</Text>

                    <Box>
                      <Text as='span'>Didn’t work? </Text>
                      <NavLink to='/login?via_extension'>
                        <Text
                          as='span'
                          color='purchr.brown.dark'
                          fontWeight='700'
                        >{` Try logging in again here > `}</Text>
                      </NavLink>
                    </Box>
                  </Flex>
                </CardBody>
              </Card>
            </Flex>
          </Box>
        </GridItem>
        <GridItem
          colSpan={{ base: 0, xl: 5, '2xl': 6 }}
          display={{ base: 'none', xl: 'block' }}
          alignSelf='start'
          mt='60px'
        >
          <Box>
            <Flex h={'100%'} direction={'column'}>
              <Text
                mt='24px'
                maxW='397px'
                as='span'
                alignSelf='center'
                color='purchr.blue.dark'
                fontWeight='400'
                fontSize='24px'
              >
                Alerting you to
                <Text
                  as='span'
                  color='purchr.brown.dark'
                  fontWeight='700'
                  fontSize='24px'
                  ml='6px'
                >
                  price drops
                </Text>{' '}
                on your favourite items, everywhere.
              </Text>
              <Box h={`calc(${window.innerHeight}px - 156px)`}>
                <Flex h='100%' alignItems='center' justifyContent='center'>
                  <Image src={HeroImage} zIndex='1' />
                </Flex>
              </Box>
            </Flex>
          </Box>
        </GridItem>
      </Grid>
    </Box>
  );
}
